import {Button} from 'reactstrap';

import {useAlerts} from '@reasoncorp/kyber-js';

import {ComponentLayout, DemoContainer} from '../../components';
import componentLinks from './componentLinks';

const exampleCode = `import {Button} from 'reactstrap';

import {useAlerts} from '@reasoncorp/kyber-js';

const AlertsExample = () => {
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  return (
    <div>
      <Button color="success" className="mr-2"
              onClick={() => showSuccessAlert('Success Alert')}>Click for Success Alert</Button>
      <Button color="danger" className="mr-2"
              onClick={() => showErrorAlert('Error Alert')}>Click for Error Alert</Button>
      <Button color="danger"
              onClick={() => showErrorAlert('Error Alert', true)}>Click for Error Alert Requiring Acknowledgement</Button>
    </div>
  );
};

export default AlertsExample;`;

const AlertsExample = () => {
  const {showErrorAlert, showSuccessAlert} = useAlerts();

  return (
    <DemoContainer name="Alerts" section="Components" componentLinks={componentLinks}>
      <ComponentLayout description={
        <p>
          Alerts are displayed as Growl Notifications for success or failure. Your code will need
          to wrap the main App component with <code>AlertsProvider</code>. See <code>index.tsx</code> in this demo
          for an example. An optional boolean argument to disable the alert timeout can be passed to <code>showSuccessAlert</code> and
          <code>showErrorAlert</code>.
        </p>
      }
                       componentCodeToRender={exampleCode}>
        <div>
          <Button color="success" className="mr-2"
                  onClick={() => showSuccessAlert('Success Alert')}>Click for Success Alert</Button>
          <Button color="danger" className="mr-2"
                  onClick={() => showErrorAlert('Error Alert')}>Click for Error Alert</Button>
          <Button color="danger"
                  onClick={() => showErrorAlert('Error Alert! Click to close', true)}>Click for Error Alert Requiring Acknowledgement</Button>
        </div>
      </ComponentLayout>
    </DemoContainer>
  );
};

export default AlertsExample;