import {useState} from 'react';

import {CustomTable, ResultsLimiter} from '@reasoncorp/kyber-js';

import Item from '../Item';
import {ComponentLayout} from '../../../components';

const exampleCode = `import {useState} from 'react';
import {CustomTable, ResultsLimiter} from '@reasoncorp/kyber-js';

import Item from '../Item';

const ResultsLimiterExample2 = () => {
  const tableProps = {
    headers: [
      {title: 'ID', sortKey: 'id', className: 'align-middle text-center'},
      {title: 'First Name', sortKey: 'firstName', className: 'align-middle'},
      {title: 'Last Name', sortKey: 'lastName', className: 'align-middle'},
      {title: 'Certification Level', sortKey: 'certificationLevel', className: 'align-middle text-center'},
      {title: 'Certification Number', sortKey: 'certificationNumber', className: 'align-middle text-center'}
    ],
    initialSort: {sortKey: 'lastName', direction: 'asc' as const},
    items: [
      {id: 1, firstName: 'Philip', lastName: 'Calouche', certificationLevel: null, certificationNumber: null},
      {id: 2, firstName: 'Becca', lastName: 'Kinard', certificationLevel: 'MCAO', certificationNumber: 'R-2222'},
      {id: 3, firstName: 'Justin', lastName: 'Baker', certificationLevel: 'MAAO', certificationNumber: 'R-3333'},
      {id: 4, firstName: 'Jesse', lastName: 'West', certificationLevel: 'MMAO', certificationNumber: 'T-4444'}
    ],
    renderRow: (item: Item) => {
      return (
        <tr key={item.id}>
          <td className="align-middle text-center">{item.id}</td>
          <td className="align-middle">{item.firstName}</td>
          <td className="align-middle">{item.lastName}</td>
          <td className="align-middle text-center">{item.certificationLevel}</td>
          <td className="align-middle text-center">{item.certificationNumber}</td>
        </tr>
      );
    },
    renderFooter: () => {
      return (
        <tr>
          <td className="align-middle text-center"
              colSpan={tableProps.headers.length}>
            Hey you are currently showing {resultLimit ? resultLimit : 'All'} items.
          </td>
        </tr>
      );
    }
  };

  // This is React Hooks way of handling state. Classic React state management can be used as well.
  const [resultLimit, setResultLimit] = useState<number | null>(2);

  const handleResultsChange = (resultLimit: number | null) => {
    setResultLimit(resultLimit);
  };

  return (
    <>
      <CustomTable className="mb-0"
                   headers={tableProps.headers}
                   sortable
                   initialSort={tableProps.initialSort}
                   items={tableProps.items}
                   renderRow={tableProps.renderRow}
                   renderFooter={tableProps.renderFooter}
                   resultLimit={resultLimit}/>
      <ResultsLimiter message="Items to Show:"
                      recordName="Item"
                      pluralRecordName="Items"
                      limitOptions={[
                        {displayValue: '2', value: 2},
                        {displayValue: '4', value: 4},
                        {displayValue: 'All', value: null}
                      ]}
                      resultLimit={resultLimit}
                      totalRecords={tableProps.items.length}
                      handleClick={handleResultsChange}/>
    </>
  );
};

export default ResultsLimiterExample2;`;

const ResultsLimiterExample2 = () => {
  const [resultLimit, setResultLimit] = useState<number | null>(2);
  const tableProps = {
    headers: [
      {title: 'ID', sortKey: 'id', className: 'align-middle text-center'},
      {title: 'First Name', sortKey: 'firstName', className: 'align-middle'},
      {title: 'Last Name', sortKey: 'lastName', className: 'align-middle'},
      {title: 'Certification Level', sortKey: 'certificationLevel', className: 'align-middle text-center'},
      {title: 'Certification Number', sortKey: 'certificationNumber', className: 'align-middle text-center'}
    ],
    initialSort: {sortKey: 'lastName', direction: 'asc' as const},
    items: [
      {id: 1, firstName: 'Philip', lastName: 'Calouche', certificationLevel: null, certificationNumber: null},
      {id: 2, firstName: 'Becca', lastName: 'Kinard', certificationLevel: 'MCAO', certificationNumber: 'R-2222'},
      {id: 3, firstName: 'Justin', lastName: 'Baker', certificationLevel: 'MAAO', certificationNumber: 'R-3333'},
      {id: 4, firstName: 'Jesse', lastName: 'West', certificationLevel: 'MMAO', certificationNumber: 'T-4444'}
    ],
    renderRow: (item: Item) => {
      return (
        <tr key={item.id}>
          <td className="align-middle text-center">{item.id}</td>
          <td className="align-middle">{item.firstName}</td>
          <td className="align-middle">{item.lastName}</td>
          <td className="align-middle text-center">{item.certificationLevel}</td>
          <td className="align-middle text-center">{item.certificationNumber}</td>
        </tr>
      );
    },
    renderFooter: () => {
      return (
        <tr>
          <td className="align-middle text-center"
              colSpan={tableProps.headers.length}>
            Hey you are currently showing {resultLimit ? resultLimit : 'All'} items.
          </td>
        </tr>
      );
    }
  };

  const handleResultsChange = (resultLimit: number | null) => {
    setResultLimit(resultLimit);
  };

  return (
    <ComponentLayout exampleTitle="A CustomTable with a ResultsLimiter that is controlled outside the CustomTable.
    This is useful if you need to do some custom rendering between your table and results limiter."
                     componentCodeToRender={exampleCode}>
      <CustomTable className="mb-0"
                   headers={tableProps.headers}
                   initialSort={tableProps.initialSort}
                   items={tableProps.items}
                   renderRow={tableProps.renderRow}
                   renderFooter={tableProps.renderFooter}
                   resultLimit={resultLimit}/>
      <ResultsLimiter message="Items to Show:"
                      recordName="Item"
                      pluralRecordName="Items"
                      limitOptions={[
                        {displayValue: '2', value: 2},
                        {displayValue: '4', value: 4},
                        {displayValue: 'All', value: null}
                      ]}
                      resultLimit={resultLimit}
                      totalRecords={tableProps.items.length}
                      handleClick={handleResultsChange}/>
    </ComponentLayout>
  );
};

export default ResultsLimiterExample2;