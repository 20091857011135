import {Button, Card, CardBody, Col, Form, Row} from 'reactstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';

import {FormikRadioGroup} from '@reasoncorp/kyber-js';

import {ComponentLayout, DemoContainer} from '../../components';
import componentLinks from './componentLinks';

const radioSource = `import {Card, CardBody, Col, Form, Row} from 'reactstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';

import {FormikRadioGroup} from '@reasoncorp/kyber-js';

const RadioExample = () => {
  const initialValues = {
    stackedRadioAnswer: '',
    inlineRadioAnswer: ''
  };

  const validationSchema = Yup.object().shape({
    stackedRadioAnswer: Yup.string().oneOf(['Yes', 'No']).required('Required'),
    inlineRadioAnswer: Yup.string().oneOf(['Yes', 'No'])
  });

  return (
    <Formik initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              // Normally this would be an API call to a server.
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
                actions.setSubmitting(false);
              }, 250);
            }}>
      {formikProps => {
        return (
          <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
            <Row>
              <Col xs="6">
                <Card>
                  <CardBody>
                    <FormikRadioGroup name="stackedRadioAnswer"
                                      labelText="Stacked Radio Button Group"
                                      onChange={(e) => {
                                        console.log('This how to handle an additional onChange event', e.target.value);
                                      }}
                                      radioButtons={[
                                        {value: 'Yes', labelText: 'Yes'},
                                        {value: 'No', labelText: 'No'}
                                      ]}/>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="6" className="row-eq-height">
                <Card className="h-100">
                  <CardBody>
                    <FormikRadioGroup name="inlineRadioAnswer"
                                      labelText="Inline Radio Button Group"
                                      inline
                                      radioButtons={[
                                        {value: 'Yes', labelText: 'Yes'},
                                        {value: 'No', labelText: 'No'}
                                      ]}/>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="d-flex justify-content-end">
                <Button type="submit" onClick={formikProps.submitForm}>Submit</Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
}
export default RadioExample;`;

const radioProps = `type RadioButton = {
  id?: string
  value?: string
  labelText?: string
  ariaLabel?: string
  disabled?: boolean
}

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  [key: string]: any
  name: string
  labelText?: string
  inline?: boolean
  formGroupClass?: string
  disabled?: boolean
  radioButtons: RadioButton[]
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, helpers: FieldHelperProps<any>) => void
  noFormikOnChange?: boolean
}`;

const FormikRadioGroupExample = () => {
  const initialValues = {
    stackedRadioAnswer: '',
    inlineRadioAnswer: ''
  };

  const validationSchema = Yup.object().shape({
    stackedRadioAnswer: Yup.string().oneOf(['Yes', 'No']).required('Required'),
    inlineRadioAnswer: Yup.string().oneOf(['Yes', 'No'])
  });

  return (
    <DemoContainer name="FormikRadioGroup" section="Forms" componentLinks={componentLinks}>
      <ComponentLayout
        exampleTitle="stacked and inline"
        description={
          <p>
            <code>FormikRadioGroup</code> should be used to change settings or make a single selection, not as action buttons to perform commands. Radio buttons should be used for selections that require the user to select exactly one option.
          </p>}
        componentCodeToRender={radioSource}
        componentPropsToRender={radioProps}>
        <Formik initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  // Normally this would be an API call to a server.
                  setTimeout(() => {
                    alert(JSON.stringify(values, null, 2));
                    actions.setSubmitting(false);
                  }, 250);
                }}>
          {formikProps => {
            return (
              <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
                <Row>
                  <Col xs="6">
                    <Card>
                      <CardBody>
                        <FormikRadioGroup name="stackedRadioAnswer"
                                          labelText="Stacked Radio Button Group"
                                          onChange={(e) => {
                                            console.log('This how to handle an additional onChange event', e.target.value);
                                          }}
                                          radioButtons={[
                                            {value: 'Yes', labelText: 'Yes'},
                                            {value: 'No', labelText: 'No'}
                                          ]}/>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xs="6" className="row-eq-height">
                    <Card className="h-100">
                      <CardBody>
                        <FormikRadioGroup name="inlineRadioAnswer"
                                          labelText="Inline Radio Button Group"
                                          inline
                                          radioButtons={[
                                            {value: 'Yes', labelText: 'Yes'},
                                            {value: 'No', labelText: 'No'}
                                          ]}/>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col className="d-flex justify-content-end">
                    <Button type="submit" onClick={formikProps.submitForm}>Submit</Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </ComponentLayout>
    </DemoContainer>
  );
};

export default FormikRadioGroupExample;
