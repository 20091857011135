import Code from '../../components/Code';
import {DemoContainer} from '../../components';
import componentLinks from './componentLinks';

const restApiHelperCode = `import {restApiHelper} from '@reasoncorp/kyber-js';

// first, create a new instance of RestApiHelper by defining a base path for requests
const restApiHelper = new RestApiHelper({path: "http://localhost:3000"});

// Use the instance of RestApiHelper to define functions to fetch requests
const getVersion = (): Promise<{reactKyberVersion: string}> => restApiHelper.getWithJsonResponse('/VERSION.json');

// The preferred pattern for using these functions is with await.
const version = await getVersion();
`;

const allHttpMethodsCode = `// GET /record/:id
const getRecord = (id: number): Promise<Record> => restApiHelper.postWithJsonResponse(\`/record/\${id}\`);

// POST /record/:id
const createRecord = (id: number, recordRequest: RecordRequest): Promise<Record> => restApiHelper.postWithJsonResponse(\`/record/\${id}\`, {body: recordRequest});

// PUT /record/:id
const updateRecord = (id: number, recordRequest: RecordRequest): Promise<Record> => restApiHelper.putWithJsonResponse(\`/record/\${id}\`, {body: recordRequest});

// PATCH /record/:id
const updateRecordStatus = (id: number, recordStatusRequest: RecordStatusRequest): Promise<Record> => restApiHelper.patchWithJsonResponse(\`/record/\${id}\`, {body: recordStatusRequest});

// DELETE /record/:id
const deleteRecord = (id: number): Promise<Record> => restApiHelper.deleteWithJsonResponse(\`/record/\${id}\`);

// All json request methods take the same arguments; (path, fetchConfig = {}, options = {}).
// fetchConfig is an object that is merged with the defaultFetchConfig provided to RestApiHelper on instantiation for 
// arguments passed to the fetch call.
// 
// options is an object used to provide additional behavior the the request functions that is not passed directly
// to the fetch call, such as ignoreLastActivity. By default, every request updates localStorage.lastActivity 
// with the timestamp of the request. This can be disabled per request. 
// example:
restApiHelper.postWithJsonResponse('client-auth/jwt',{credentials: 'include'}, {ignoreLastActivity: true}); 

// suppressUnauthorizedHandler may also be passed as an option to requests to ignore any unauthorized responses.
restApiHelper.getWithJsonResponse('/dont-care-if-unauth', {}, {suppressUnauthorizedHandler: true});
`;

const nonJsonHttpMethodsCode = `// GET /file/:id
const getFileUrl = (id: number): Promise<string> => restApiHelper.getWithTextResponse(\`/file/\${id}\`);

// All HTTP Methods GET, POST, PUT, PATCH, and DELETE are supported for handling text responses.
const uploadFileAndGetUrl = (id: number): Promise<String> => restApiHelper.postWithTextResponse(\`/file/\${id}\`);

// Responses without a body(or empty response) may be defined.
// All HTTP Methods GET, POST, PUT, PATCH, and DELETE are supported for handling empty responses.
const deleteWithEmptyResponse = (id: number): Promise<void> => restApiHelper.deleteWithEmptyResponse(\`/file/\${id}\`);

// File Blob responses are also supported for requests returning raw file data.
// All HTTP Methods GET, POST, PUT, PATCH, and DELETE are supported for handling blob responses.
const getFileData = (id: number): Promise<Response> => restApiHelper.getWithBlobResponse(\`/file/\${id}\`);
`;

const optionsCode = `type RestApiHelperConfig = {
  path: string
  jwtKey?: string
  lastActivityCookieDomain?: string
  unauthorizedHandler?: () => void
}

new RestApiHelper({
  path: 'https://sso-api.qa.misuite.app',
  lastActivityCookieDomain: 'qa.misuite.app,
  unauthorizedHandler: () => alert('You are unauthorized!')
});
`;

const RestApiHelperExample = () => {
  return <DemoContainer name="RestApiHelper" section="Utilities" componentLinks={componentLinks}>
    <p>
      RestApiHelper is a class for defining sets of <code>fetch</code> requests with common options for sending requests and handling responses.
    </p>
    <h2 className="figure-caption text-left">Example: usage</h2>
    <Code code={restApiHelperCode}/>
    <p>
      Requests with all HTTP methods can be defined using the instance of RestApiHelper.
    </p>
    <h2 className="figure-caption text-left">Example: all http methods</h2>
    <Code code={allHttpMethodsCode}/>
    <p>
      Requests without JSON responses may also be defined.
    </p>
    <h2 className="figure-caption text-left">Example: all non-json requests</h2>
    <Code code={nonJsonHttpMethodsCode}/>
    <p>
      RestApiHelper can take <code>path</code>, <code>jwtKey</code>, <code>lastActivityCookieDomain</code>,
      and <code>unauthorizedHandler</code> as options.
      <br/>
      <code>jwtKey</code> the local storage key to use for storing the JWT. Will default to 'JWT' if not specified.
      <br/>
      <code>lastActivityCookieDomain</code> use to monitor last activity across multiple domains the SSO instance may
      serve. These domains should have a common root. For instance, <strong>sso.qa.misuite.app</strong>,
      <strong>amar.qa.misuite.app</strong>, and <strong>cb.qa.misuite.app</strong> all have
      <strong>qa.misuite.app</strong> as a common root. This is meant to coordinate the last activity across an
      application that spans multiple domains such as MiSuite or ProSuite.
      <br/>
      <code>unauthorizedHandler</code> defines a function to be called if any request made using the instance of RestApiHelper
      returns a 401 unauthorized response.
    </p>
    <h2 className="figure-caption text-left">Example: all available config options</h2>
    <Code code={optionsCode}/>
  </DemoContainer>;
};

export default RestApiHelperExample;