import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {ButtonIcon, CustomTable} from '@reasoncorp/kyber-js';

import {ComponentLayout} from '../../../components';
import Item from '../Item';

const exampleCode = `import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {ButtonIcon, CustomTable} from '@reasoncorp/kyber-js';

import Item from '../Item';

const SortableTable = () => {
  const tableProps = {
    headers: [
      {title: 'ID', sortKey: 'id', className: 'align-middle text-center'},
      {title: 'First Name', sortKey: 'firstName', className: 'align-middle'},
      {title: 'Last Name', sortKey: 'lastName', className: 'align-middle'},
      {title: 'Active', sortKey: 'active', className: 'align-middle text-center'},
      {title: 'Delete', className: 'align-middle text-center'}
    ],
    initialSort: {sortKey: 'lastName', direction: 'asc' as const},
    items: [
      {id: 1, firstName: 'Philip', lastName: 'Calouche', active: true},
      {id: 2, firstName: 'Becca', lastName: 'Kinard', active: false},
      {id: 3, firstName: 'Justin', lastName: 'Baker', active: true},
      {id: 4, firstName: 'Jesse', lastName: 'West', active: false}
    ],
    renderRow: (item: Item) => {
      return (
        <tr key={item.id}>
          <td className="align-middle text-center">{item.id}</td>
          <td className="align-middle">{item.firstName}</td>
          <td className="align-middle">{item.lastName}</td>
          <td className="align-middle text-center">
            {item.active ?
              <FontAwesomeIcon icon="check"
                               className="text-success"
                               title="active"
                               aria-label="Active"/> :
              <FontAwesomeIcon icon="times"
                               className="text-danger"
                               title="inactive"
                               aria-label="Inactive"/>
            }
          </td>
          <td className="align-middle text-center">
            <ButtonIcon icon="trash"
                        title="Delete role"
                        className="text-danger"
                        ariaLabel="Delete role"
                        onClick={() => alert('You clicked on ' + item.firstName + ' ' + item.lastName + '.')}/>
          </td>
        </tr>
      );
    }
  };

  return <CustomTable {...tableProps}/>;
};

export default SortableTable;`;

const SortableTable = () => {
  const tableProps = {
    headers: [
      {title: 'ID', sortKey: 'id', className: 'align-middle text-center'},
      {title: 'First Name', sortKey: 'firstName', className: 'align-middle'},
      {title: 'Last Name', sortKey: 'lastName', className: 'align-middle'},
      {title: 'Active', sortKey: 'active', className: 'align-middle text-center'},
      {title: 'Delete', className: 'align-middle text-center'}
    ],
    initialSort: {sortKey: 'lastName', direction: 'asc' as const},
    items: [
      {id: 1, firstName: 'Philip', lastName: 'Calouche', active: true},
      {id: 2, firstName: 'Becca', lastName: 'Kinard', active: false},
      {id: 3, firstName: 'Justin', lastName: 'Baker', active: true},
      {id: 4, firstName: 'Jesse', lastName: 'West', active: false}
    ],
    renderRow: (item: Item) => {
      return (
        <tr key={item.id}>
          <td className="align-middle text-center">{item.id}</td>
          <td className="align-middle">{item.firstName}</td>
          <td className="align-middle">{item.lastName}</td>
          <td className="align-middle text-center">
            {item.active ?
              <FontAwesomeIcon icon="check"
                               className="text-success"
                               title="active"
                               aria-label="Active"/> :
              <FontAwesomeIcon icon="times"
                               className="text-danger"
                               title="inactive"
                               aria-label="Inactive"/>
            }
          </td>
          <td className="align-middle text-center">
            <ButtonIcon icon="trash"
                        title="Delete role"
                        className="text-danger"
                        ariaLabel="Delete role"
                        onClick={() => alert('You clicked on ' + item.firstName + ' ' + item.lastName + '.')}/>
          </td>
        </tr>
      );
    }
  };

  return (
    <div id="sortable-table">
      <ComponentLayout exampleTitle="A sortable CustomTable with an initial sort and sortable icon. Sortable columns
      cycle from ascending sort to descending sort and then back to no sort. Sort keys can be nested properties such
      as user.firstName. Sort can also be chained see example below."
                       componentCodeToRender={exampleCode}>
        <CustomTable {...tableProps}/>
      </ComponentLayout>
    </div>
  );
};

export default SortableTable;