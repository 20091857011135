import {Col, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {ComponentLayout, DemoContainer} from '../../components';
import componentLinks from './componentLinks';

const exampleCode = `// Reference the font awesome icon library from Kyber Js.
// This library will have icons needed by all Kyber Js components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {kyberFontAwesomeLibrary} from '@reasoncorp/kyber-js';
// import the icons the app will need here
import {faCoffee} from '@fortawesome/free-solid-svg-icons';
// Add any additional icons your app needs to the library.
// This avoids having to explicitly import them in your components.
kyberFontAwesomeLibrary.add(
  faCoffee
);

// reference the icons by their names as strings
// the fas prefix is being inferred as default
<FontAwesomeIcon icon="coffee" size="sm" />

// icons are sized relative to their container
<FontAwesomeIcon icon="coffee" size="lg" />
<FontAwesomeIcon icon="coffee" size="2x" />
<FontAwesomeIcon icon="coffee" size="3x" />
<FontAwesomeIcon icon="coffee" size="4x" />
<FontAwesomeIcon icon="coffee" size="5x" />
<FontAwesomeIcon icon="coffee" size="6x" />
// fixed width, inverse, listItem
<FontAwesomeIcon icon="coffee" fixedWidth />
<FontAwesomeIcon icon="coffee" inverse />
<FontAwesomeIcon icon="coffee" listItem />
// rotate
<FontAwesomeIcon icon="coffee" rotation="{90}" />
<FontAwesomeIcon icon="coffee" rotation="{180}" />
<FontAwesomeIcon icon="coffee" rotation="{270}" />
// transform
<FontAwesomeIcon icon="coffee" flip="horizontal" />
<FontAwesomeIcon icon="coffee" flip="vertical" />
<FontAwesomeIcon icon="coffee" flip="both" />
// animate
<FontAwesomeIcon icon="coffee" spin />
<FontAwesomeIcon icon="coffee" pulse />
// border
<FontAwesomeIcon icon="coffee" border />
// pull
<FontAwesomeIcon icon="coffee" pull="left" />
<FontAwesomeIcon icon="coffee" pull="right" />
// power transform
<FontAwesomeIcon icon="coffee" transform="shrink-6 left-4" />
<FontAwesomeIcon icon="coffee" transform={{ rotate: 42 }} />
<FontAwesomeIcon icon="coffee" mask={['far', 'circle']} />
// symbols
<FontAwesomeIcon icon="coffee" symbol />
<FontAwesomeIcon icon="coffee" symbol="edit-icon" />
// layering
<span className="fa-layers fa-fw">
  <FontAwesomeIcon icon="coffee" color="green" />
  <FontAwesomeIcon icon="coffee" inverse transform="shrink-6" />
</span>
// custom class
<FontAwesomeIcon icon="coffee" className="my-style" />`;

const IconsExample = () => {
  return (
    <DemoContainer name="Icons" section="Brand" componentLinks={componentLinks}>
      <ComponentLayout
        description={
          <p>
            Adding icons to an interface provides a visual focus point that directs a user to a
            specific section or action. Icons that are used for visual or branding reinforcement and
            if removed, the application would still be understood by the users, are considered
            decorative. Icons that are used for focusable interactive elements are considered semantic
            icons. Font Awesome is utilized within the library for its robust set of scalable vector
            icons with basic styling, power transforms, browser compatibility and auto-accessibility
            feature for decorative icons.
          </p>
        }
        exampleTitle="Icons"
        componentCodeToRender={exampleCode}>
        <Row>
          <Col xs={2}>
            <FontAwesomeIcon icon="coffee" size="lg"/>
          </Col>
          <Col xs={2}>
            <FontAwesomeIcon icon="coffee" size="lg" color="blue"/>
          </Col>
          <Col xs={2}>
            <FontAwesomeIcon icon="coffee" size="lg" spin/>
          </Col>
          <Col xs={2}>
            <FontAwesomeIcon icon="coffee" size="lg" flip="vertical"/>
          </Col>
          <Col xs={2}>
            <FontAwesomeIcon icon="coffee" size="lg" transform={{rotate: 42}}/>
          </Col>
          <Col xs={2}>
            <FontAwesomeIcon icon="coffee" size="lg" transform="shrink-6 left-4"/>
          </Col>
        </Row>
      </ComponentLayout>
    </DemoContainer>
  );
};

export default IconsExample;
