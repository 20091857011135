import {AppBar, SsoNotificationButton, UserNotification, UserProfile} from '@reasoncorp/kyber-js';

import {ComponentLayout, DemoContainer} from '../../components';
import {RkLogo} from '../../images';
import componentLinks from './componentLinks';

const exampleCode = `import {AppBar, SsoNotificationButton, SsoNotification, UserNotification, UserProfile} from '@reasoncorp/kyber-js';

import {RkLogo} from './index';

const NavigationExample = () => {
    const ssoApi: {
      findNotifications: () => Promise<UserNotification>
    } = {
      findNotifications: () => new Promise(() => userNotification)
    };
    const userNotification = {
    unread: [
      {
        id: 1,
        text: 'Notification 1',
        timeAgo: '1 minute ago',
        subject: 'Notification 1',
        read: false,
        application: 'MEG',
        createdAt: new Date()
      },
      {
        id: 2,
        subject: 'Notification that is longer',
        text: 'Notification that is longer',
        timeAgo: '5 minutes ago',
        read: false,
        application: 'MEG',
        createdAt: new Date()
      },
      {
        id: 3,
        subject: 'This notification is particularly wordy. I am curious how well it will wrap the text.',
        text: 'This notification is particularly wordy. I am curious how well it will wrap the text.',
        timeAgo: '1 hour ago',
        read: false,
        application: 'MEG',
        createdAt: new Date()
      }],
    read: [],
    archived: []
  } as UserNotification;
   
  const appBarProps = {
    brandLink: '/',
    brandImage: RkLogo,
    brandImageAlt: 'MiSuite',
    appName: 'React Kyber Component Library',
    organizationName: 'Reason Consulting',
    routes: [
      {name: 'Layout', to: '/layout'}
    ],
    // Some dummy findNotifications for testing
    userNotification,
    // Each app may need to control how its AppBar widgets are configured because they may need to reference other app logic.
    // By creating render props for each of these widgets this allows for easier composition of these widgets.
    renderNotificationButton: () =>
      // A non-demo application will have other checks in here such as to render nothing if the user is not logged in
      <SsoNotificationButton ssoApi={ssoApi}
                             onNotificationsClick={() => alert('would navigate to MiMessaging')}/>,
    renderUserProfile: () =>
      // A non-demo application will have other checks in here such as to render nothing if the user is not logged in
      <UserProfile handleProfileClick={() => alert('In a real app this would go to your profile.')}
                    handleSignOut={() => alert('You cannot actually sign out because you are not signed in.')}/>
  };

  return (
    <AppBar {...appBarProps}/>
  );
};

export default NavigationExample;`;

// TODO where do we put shared props?
// const typeScriptProps = `type AppBarRoute = {
//   name: string
//   to: string
//   exact?: boolean
//   hide?: boolean
// }
// `;

const componentProps = [
  {name: 'brandLink', type: 'string'},
  {name: 'brandImage', type: 'string'},
  {name: 'brandImageAlt', type: 'string'},
  {name: 'appName', type: 'string'},
  {name: 'environmentName?', type: 'string', required: false},
  {name: 'organizationName', type: 'string'},
  {name: 'routes?', type: 'AppBarRoute[]', defaultValue: '[]', required: false},
  {name: 'renderRoutes?', type: '() => ReactNode', required: false},
  {name: 'renderNotificationButton?', type: '() => ReactNode', required: false},
  {name: 'renderUserProfile?', type: '() => ReactNode', required: false},
  {name: 'light?', type: 'boolean', defaultValue: 'true', required: false},
  {name: 'dark?', type: 'boolean', defaultValue: 'false', required: false},
  {name: 'expand?', type: '\'sm\' | \'md\' | \'lg\'', defaultValue: 'md', required: false}
];

const AppBarExample = () => {
  const ssoApi: {
    findNotifications: () => Promise<UserNotification>
  } = {
    findNotifications: () => new Promise(() => userNotification)
  };

  const userNotification = {
    unread: [
      {
        id: 1,
        text: 'Notification 1',
        timeAgo: '1 minute ago',
        subject: 'Notification 1',
        read: false,
        application: 'MEG',
        createdAt: new Date()
      },
      {
        id: 2,
        subject: 'Notification that is longer',
        text: 'Notification that is longer',
        timeAgo: '5 minutes ago',
        read: false,
        application: 'MEG',
        createdAt: new Date()
      },
      {
        id: 3,
        subject: 'This notification is particularly wordy. I am curious how well it will wrap the text.',
        text: 'This notification is particularly wordy. I am curious how well it will wrap the text.',
        timeAgo: '1 hour ago',
        read: false,
        application: 'MEG',
        createdAt: new Date()
      },
      {
        id: 4,
        text: 'Finish the MEG application.',
        subject: 'Finish the MEG application.',
        timeAgo: '15 hours ago',
        read: false,
        application: 'MEG',
        createdAt: new Date()
      }, {
        id: 5,
        text: 'Finish the MEGA application.',
        subject: 'Finish the MEGA application.',
        timeAgo: '1 day ago',
        read: false,
        application: 'MEG',
        createdAt: new Date()
      }, {
        id: 6,
        text: 'Finish the PA660 application.',
        subject: 'Finish the PA660 application.',
        timeAgo: '1,234 days ago',
        read: false,
        application: 'MEG',
        createdAt: new Date()
      }, {
        id: 7,
        subject: 'Convert SSO to TypeScript.',
        text: 'Convert SSO to TypeScript.',
        timeAgo: 'Less than a minute ago',
        read: false,
        application: 'MEG',
        createdAt: new Date()
      }],
    read: [],
    archived: []
  } as UserNotification;

  const appBarProps = {
    brandLink: '/',
    brandImage: RkLogo,
    brandImageAlt: 'React Kyber Logo',
    appName: 'React Kyber Component Library',
    organizationName: 'Reason Consulting',
    routes: [
      {name: 'Home', to: '/'}
    ],
    // Some dummy findNotifications for testing
    userNotification,
    // Each app may need to control how its AppBar widgets are configured because they may need to reference other app logic.
    // By creating render props for each of these widgets this allows for easier composition of these widgets.
    renderNotificationButton: () =>
      // A non-demo application will have other checks in here such as to render nothing if the user is not logged in
      <SsoNotificationButton ssoApi={ssoApi}
                             onNotificationsClick={() => alert('would navigate to MiMessaging')}/>,
    renderUserProfile: () =>
      // A non-demo application will have other checks in here such as to render nothing if the user is not logged in
      <UserProfile handleProfileClick={() => alert('In a real app this would go to your profile.')}
                   handleSignOut={() => alert('You cannot actually sign out because you are not signed in.')}/>
  };

  return (
    <DemoContainer name="AppBar"
                   section="Layout"
                   componentLinks={componentLinks}>
      <ComponentLayout exampleTitle="AppBar"
                       componentProps={componentProps}
                       description={
                         <p>
                           The full width, sticky <code>AppBar</code> component provides hierarchy and structure to the
                           site content and actions related to the current screen. <code>AppBar</code> is
                           a required component that should be imported in consuming applications within
                           the <code>App.tsx</code> file and rendered before the main section.
                         </p>
                       }
                       componentCodeToRender={exampleCode}>
        <AppBar {...appBarProps}/>
      </ComponentLayout>
    </DemoContainer>
  );
};

export default AppBarExample;
