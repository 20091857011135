import {DemoContainer} from '../../components';
import componentLinks from './componentLinks';
import {PaginatorExample1, PaginatorExample2, PaginatorExample3} from './paginator';

const PaginatorExample = () => {
  return <DemoContainer name="Paginator" section="Tables" componentLinks={componentLinks}>
    <p>
      <code>Paginator</code> allows a developer to limit the number of items shown in a table to a specified number,
      split into separate pages of records.
    </p>
    <hr/>
    <PaginatorExample1/>
    <hr/>
    <PaginatorExample2/>
    <hr/>
    <PaginatorExample3/>
  </DemoContainer>;
};

export default PaginatorExample;