import {Button, Card, CardBody, Col, Form, Row} from 'reactstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {addDays, format, subDays} from 'date-fns';

import {FormikDatePicker, YupDate} from '@reasoncorp/kyber-js';

import {ComponentLayout, DemoContainer} from '../../components';
import {bootStrapTypeCode} from '../../sharedExampleCode';
import componentLinks from './componentLinks';

const datepickerCode = `import {Button, Card, CardBody, Col, Form, Row} from 'reactstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {addDays, format, subDays} from 'date-fns';

import {FormikDatePicker, YupDate} from '@reasoncorp/kyber-js';

const DatepickerExample = () => {
  // Create a date string that will be in range of the date picker's allowed dates
  const today = new Date();

  const initialValues = {
    dateValue1: today,
    dateValue2: '',
    dateValue3: null
  };

  const minDateMessage = (date: Date) => 'Must be later than ' + format(date, 'MM/dd/yyyy');
  const maxDateMessage = (date: Date) => 'Must be earlier than ' + format(date, 'MM/dd/yyyy');

  const validationSchema = Yup.object().shape({
    dateValue1: YupDate()
      .typeError('Must be a valid date')
      .min(subDays(today, 5), minDateMessage(subDays(today, 5)))
      .max(addDays(today, 10), maxDateMessage(addDays(today, 10))),
    dateValue2: YupDate()
      .typeError('Must be a valid date')
      .required('Required')
      .min(subDays(today, 5), minDateMessage(subDays(today, 5)))
      .max(addDays(today, 10), maxDateMessage(addDays(today, 10))),
    dateValue3: YupDate()
      .typeError('Must be a valid date')
      .nullable()
      .min(subDays(today, 5), minDateMessage(subDays(today, 5)))
      .max(addDays(today, 10), maxDateMessage(addDays(today, 10)))
  });

  const isWeekday = (date: Date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  return (
    <Formik initialValues="{initialValues}"
            validationSchema="{validationSchema}"
            validateOnMount="{true}"
            onSubmit={(values, actions) => {
              // Normally this would be an API call to a server.
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
                actions.setSubmitting(false);
              }, 250);
            }}>
      {formikProps => {
        return (
          <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
            <Row className="mb-2">
              <Col xs="4">
                <Card>
                  <CardBody>
                    <FormikDatePicker name="dateValue1"
                                      labelText="Date Value (showing all possible options)"
                                      minDate="{subDays(today," 5)}
                                      maxDate="{addDays(today," 10)}
                                      filterDate="{isWeekday}"
                                      onChange={(date: Date | string | null) => {
                                        console.log('Formik date picker has a different signature that takes a date because it uses a third party date picker behind the scenes.', date);
                                        // Must call setFieldValue yourself here to maintain form state if noFormikOnChange is set
                                        formikProps.setFieldValue('dateValue1', date);
                                      }}
                                      noFormikOnChange
                                      excludeDates={[
                                        addDays(today, 1),
                                        addDays(today, 2)
                                      ]}/>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="4">
                <Card>
                  <CardBody>
                    <FormikDatePicker name="dateValue2"
                                      labelText="Date Value (Small)"
                                      minDate="{subDays(today," 5)}
                                      maxDate="{addDays(today," 10)}
                                      filterDate="{isWeekday}"
                                      excludeDates="{["
                                        addDays(today, 1),
                                        addDays(today, 2)
                                      ]}
                                      showTodayButton
                                      bsSize="sm"/>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="4">
                <Card>
                  <CardBody>
                    <FormikDatePicker name="dateValue3"
                                      labelText="Date Value (Large)"
                                      minDate="{subDays(today," 5)}
                                      maxDate="{addDays(today," 10)}
                                      excludeDates="{["
                                        addDays(today, 1),
                                        addDays(today, 2)
                                      ]}
                                      showTodayButton
                                      bsSize="lg"/>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-end">
                <Button type="submit" onClick={formikProps.submitForm}>Submit</Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DatepickerExample;`;

const datepickerProps = bootStrapTypeCode.bootstrapFormControlSizeCode + `\n\ntype Props = {
  [key: string]: any
  id?: string
  name: string
  bsSize?: BootstrapFormControlSize
  formGroupClass?: string
  labelText?: string
  ariaLabel?: string
  icon?: {
    name: IconProp
  };
  disabled?: boolean
  onChange?: (date: Date | string | null) => void
  noFormikOnChange?: boolean
  dateFormat?: string
  placeholderText?: string
  minDate?: Date
  maxDate?: Date
  showTodayButton?: boolean
  excludeDates?: Date[]
  filterDate?: (date: Date) => boolean
  ariaRequired?: boolean
}`;

const datePickerDefaultProps = `{
  dateFormat: 'MM/dd/yyyy'
  placeholderText: 'mm/dd/yyyy'
}`;

const FormikDatePickerExample = () => {
  // Create a date string that will be in range of the date picker's allowed dates
  const today = new Date();

  const initialValues = {
    dateValue1: today,
    dateValue2: '',
    dateValue3: null
  };

  const minDateMessage = (date: Date) => 'Must be later than ' + format(date, 'MM/dd/yyyy');
  const maxDateMessage = (date: Date) => 'Must be earlier than ' + format(date, 'MM/dd/yyyy');

  const validationSchema = Yup.object().shape({
    dateValue1: YupDate()
      .typeError('Must be a valid date')
      .min(subDays(today, 5), minDateMessage(subDays(today, 5)))
      .max(addDays(today, 10), maxDateMessage(addDays(today, 10))),
    dateValue2: YupDate()
      .typeError('Must be a valid date')
      .required('Required')
      .min(subDays(today, 5), minDateMessage(subDays(today, 5)))
      .max(addDays(today, 10), maxDateMessage(addDays(today, 10))),
    dateValue3: YupDate(true)
      .typeError('Must be a valid date')
      .nullable()
      .min(subDays(today, 5), minDateMessage(subDays(today, 5)))
      .max(addDays(today, 10), maxDateMessage(addDays(today, 10)))
  });

  const isWeekday = (date: Date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  return (
    <DemoContainer name="FormikDatePicker" section="Forms" componentLinks={componentLinks}>
      <ComponentLayout
        description={<p>
          <code>FormikDatePicker</code> should be used when a form requires a date value to be inputted.
          The Kyber Js yup helper schema <code>YupDate</code> should be used in conjunction with the component in order
          to properly handle validating invalid date formats.
        </p>}
        exampleTitle="normal, small, large"
        componentCodeToRender={datepickerCode}
        componentDefaultPropsToRender={datePickerDefaultProps}
        componentPropsToRender={datepickerProps}>
        <Formik initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnMount={true}
                onSubmit={(values, actions) => {
                  // Normally this would be an API call to a server.
                  setTimeout(() => {
                    alert(JSON.stringify(values, null, 2));
                    actions.setSubmitting(false);
                  }, 250);
                }}>
          {formikProps => {
            return (
              <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
                <Row className="mb-2">
                  <Col xs="4">
                    <Card>
                      <CardBody>
                        <FormikDatePicker name="dateValue1"
                                          labelText="Date Value (showing all possible options)"
                                          minDate={subDays(today, 5)}
                                          maxDate={addDays(today, 10)}
                                          filterDate={isWeekday}
                                          onChange={(date: Date | string | null) => {
                                            console.log('Formik date picker has a different signature that takes a date because it uses a third party date picker behind the scenes.', date);
                                            // Must call setFieldValue yourself here to maintain form state if noFormikOnChange is set
                                            formikProps.setFieldValue('dateValue1', date);
                                          }}
                                          noFormikOnChange
                                          excludeDates={[
                                            addDays(today, 1),
                                            addDays(today, 2)
                                          ]}/>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xs="4">
                    <Card>
                      <CardBody>
                        <FormikDatePicker name="dateValue2"
                                          labelText="Date Value (Small)"
                                          minDate={subDays(today, 5)}
                                          maxDate={addDays(today, 10)}
                                          filterDate={isWeekday}
                                          excludeDates={[
                                            addDays(today, 1),
                                            addDays(today, 2)
                                          ]}
                                          showTodayButton
                                          bsSize="sm"/>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xs="4">
                    <Card>
                      <CardBody>
                        <FormikDatePicker name="dateValue3"
                                          labelText="Date Value (Large)"
                                          minDate={subDays(today, 5)}
                                          maxDate={addDays(today, 10)}
                                          excludeDates={[
                                            addDays(today, 1),
                                            addDays(today, 2)
                                          ]}
                                          showTodayButton
                                          bsSize="lg"/>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-end">
                    <Button type="submit" onClick={formikProps.submitForm}>Submit</Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </ComponentLayout>
    </DemoContainer>
  );
};

export default FormikDatePickerExample;
