import {Col, Row} from 'reactstrap';

import {ComponentLayout, DemoContainer} from '../../components';
import componentLinks from './componentLinks';

const exampleCode = `import {Redirect, Route, Switch} from 'react-router-dom';

import {Alerts, AppBar, Footer, NotFound, SkipNav} from '@reasoncorp/kyber-js';

const PageLayoutExample = () => {
  return (
    <>
      // render the skip navigation above the AppBar
      <SkipNav/>
      // render the AppBar
      <AppBar/>
      // render the main container with the content id to skip the navigation
      <main role="main" id="content">
        <Switch>
          // render the routes and the containers within the switch of the main section
          <Redirect exact from="/" to="/"/>
          <Route exact path="/" component={Container}/>
          // add additional routes here
          <Route exact component={NotFound}/>
        </Switch>
      </main>
      <Footer/>
      <Alerts/>
    </>
  );
};

export default PageLayoutExample;`;

const PageLayoutExample = () => {
  return (
    <DemoContainer name="Structure" section="Layout" componentLinks={componentLinks}>
      <Row className="structure">
        <Col>
          <ComponentLayout
            description={
              <p>
                The structure of applications consuming React Kyber should utilize the defined template
                for compatibility, to ensure consistency across browsers and for ADA compliance.&nbsp;
                <code>NotFound</code>, <code>SkipNav</code>, <code>AppBar</code>,
                and <code>Footer</code> are required components. The <code>main</code>&nbsp;
                element should have <code>role="main"</code> and <code>id="content"</code> to ensure
                the skip navigation functionality works as expected.
              </p>
            }
            componentCodeToRender={exampleCode}/>
        </Col>
      </Row>
    </DemoContainer>
  );
};

export default PageLayoutExample;
