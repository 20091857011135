import {Container} from 'reactstrap';

const About = () => {
  return (
    <Container className="About">
      <blockquote className="jumbotron text-center">
        {`"Long ago in forgotten times, when the Sith and Jedi fought for control of the galaxy, weapons there were, of unimaginable power. Always at their heart, a kyber crystal was." -Yoda`}
      </blockquote>
    </Container>
  );
};

export default About;
