import {Button, Card, CardBody, Col, Form, Row} from 'reactstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';

import {FormikCheckboxGroup} from '@reasoncorp/kyber-js';

import {ComponentLayout, DemoContainer} from '../../components';
import componentLinks from './componentLinks';

const checkboxCode = `import {Button, Card, CardBody, Col, Form, Row} from 'reactstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';

import {FormikCheckboxGroup} from '@reasoncorp/kyber-js';

const CheckboxExample = () => {
  const initialValues = {
    singleCheckbox: false,
    stackedCheckboxGroup1: false,
    stackedCheckboxGroup2: true,
    stackedCheckboxGroup3: false,
    inlineCheckboxGroup1: false,
    inlineCheckboxGroup2: false,
    inlineCheckboxGroup3: false,
    // Multi-value checkboxes should be an array of strings
    multiValueCheckboxGroup: ['Dogs', 'Hamsters'],
    singleSwitch: false,
    stackedSwitchGroup1: false,
    stackedSwitchGroup2: true,
    stackedSwitchGroup3: false,
    inlineSwitchGroup1: false,
    inlineSwitchGroup2: false,
    inlineSwitchGroup3: false,
    // Multi-value checkboxes should be an array of strings
    multiValueSwitchGroup: ['Dogs', 'Hamsters']
  };

  const validationSchema = Yup.object().shape({
    singleCheckbox: Yup.bool().oneOf([true], 'You must consent!'),
    stackedCheckboxGroup1: Yup.bool().oneOf([true], 'First option must be checked.'),
    stackedCheckboxGroup2: Yup.bool(),
    stackedCheckboxGroup3: Yup.bool(),
    inlineCheckboxGroup1: Yup.bool(),
    inlineCheckboxGroup2: Yup.bool(),
    inlineCheckboxGroup3: Yup.bool(),
    multiValueCheckboxGroup: Yup.array().of(Yup.string()).required('You must like at least one.'),
    singleSwitch: Yup.bool().oneOf([true], 'You must consent!'),
    stackedSwitchGroup1: Yup.bool().oneOf([true], 'First option must be checked.'),
    stackedSwitchGroup2: Yup.bool(),
    stackedSwitchGroup3: Yup.bool(),
    inlineSwitchGroup1: Yup.bool(),
    inlineSwitchGroup2: Yup.bool(),
    inlineSwitchGroup3: Yup.bool(),
    multiValueSwitchGroup: Yup.array().of(Yup.string()).required('You must like at least one.')
  });

  return (
    <Formik initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              // Normally this would be an API call to a server.
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
                actions.setSubmitting(false);
              }, 250);
            }}>
      {formikProps => {
        return (
          <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <FormikCheckboxGroup labelText="Inline Checkbox Group"
                                         inline
                                         checkboxes={[
                                           {name: 'inlineCheckboxGroup1', labelText: 'Inline Checkbox 1'},
                                           {name: 'inlineCheckboxGroup2', labelText: 'Inline Checkbox 2'},
                                           {name: 'inlineCheckboxGroup3', labelText: 'Inline Checkbox 3'}
                                         ]}/>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs="6" className="row-eq-height">
                <Card className="h-100">
                  <CardBody>
                    <FormikCheckboxGroup labelText="Single Checkbox"
                                         checkboxes={[
                                           {name: 'singleCheckbox', labelText: 'Single Checkbox'}
                                         ]}/>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="6">
                <Card>
                  <CardBody>
                    <FormikCheckboxGroup labelText="Stacked Checkbox Group"
                                         checkboxes={[
                                           {name: 'stackedCheckboxGroup1', labelText: 'Stacked Checkbox 1'},
                                           {name: 'stackedCheckboxGroup2', labelText: 'Stacked Checkbox 2'},
                                           {
                                             name: 'stackedCheckboxGroup3',
                                             labelText: 'Stacked Checkbox 3',
                                             onChange: (e) => {
                                               console.log('This how to handle an additional onChange event', e.target.checked);
                                               // do whatever you like with the value, but you need to call formik so the form change happens
                                               formikProps.handleChange(e);
                                             },
                                             noFormikOnChange: true
                                           }
                                         ]}/>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs="12">
                <Card>
                  <CardBody>
                    <FormikCheckboxGroup labelText="Multi-value Checkbox Group"
                                         inline
                                         checkboxes={[
                                           {name: 'multiValueCheckboxGroup', labelText: 'Dogs', value: 'Dogs'},
                                           {name: 'multiValueCheckboxGroup', labelText: 'Cats', value: 'Cats'},
                                           {
                                             name: 'multiValueCheckboxGroup',
                                             labelText: 'Hamsters',
                                             value: 'Hamsters'
                                           }
                                         ]}/>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <p className="mt-2">
              <code>FormikCheckboxGroup</code> can also be render as a switch by passing <code>type="switch"</code>
              as a prop. This should be used to toggle an input between two values.
            </p>
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <FormikCheckboxGroup labelText="Inline Switch Group"
                                         type="switch"
                                         inline
                                         checkboxes={[
                                           {name: 'inlineSwitchGroup1', labelText: 'Inline Switch 1'},
                                           {name: 'inlineSwitchGroup2', labelText: 'Inline Switch 2'},
                                           {
                                             name: 'inlineSwitchGroup3',
                                             labelText: 'Inline Switch 3',
                                             onChange: (e) => {
                                               console.log('This how to handle an additional onChange event', e.target.checked);
                                               // do whatever you like with the value, but you need to call formik so the form change happens
                                             }
                                           }
                                         ]}/>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs="6">
                <Card>
                  <CardBody>
                    <FormikCheckboxGroup type="switch"
                                         checkboxes={[
                                           {
                                             name: 'singleSwitch',
                                             labelText: 'I will write good comments in my code.'
                                           }
                                         ]}/>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="6">
                <Card>
                  <CardBody>
                    <FormikCheckboxGroup labelText="Stacked Switch Group"
                                         type="switch"
                                         checkboxes={[
                                           {name: 'stackedSwitchGroup1', labelText: 'Stacked Switch 1'},
                                           {name: 'stackedSwitchGroup2', labelText: 'Stacked Switch 2'},
                                           {name: 'stackedSwitchGroup3', labelText: 'Stacked Switch 3'}
                                         ]}/>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs="12">
                <Card>
                  <CardBody>
                    <FormikCheckboxGroup labelText="Multi-value Checkbox Group"
                                         type="switch"
                                         inline
                                         checkboxes={[
                                           {name: 'multiValueSwitchGroup', labelText: 'Dogs', value: 'Dogs'},
                                           {name: 'multiValueSwitchGroup', labelText: 'Cats', value: 'Cats'},
                                           {name: 'multiValueSwitchGroup', labelText: 'Hamsters', value: 'Hamsters'}
                                         ]}/>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="d-flex justify-content-end">
                <Button type="submit" onClick={formikProps.submitForm}>Submit</Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CheckboxExample;`;

const checkboxProps = `type Checkbox = React.InputHTMLAttributes<HTMLInputElement> & {
  [key: string]: any
  id?: string
  name: string
  value?: string
  labelText?: string
  ariaLabel?: string
  disabled?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  noFormikOnChange?: boolean
}

type Props = {
  labelText?: string;
  type?: 'checkbox' | 'switch';
  inline?: boolean;
  formGroupClass?: string;
  disabled?: boolean;
  checkboxes: Checkbox[];
}`;

const checkboxDefaultProps = `{
  type: 'checkbox'
}`;

const FormikCheckboxGroupExample = () => {
  const initialValues = {
    singleCheckbox: false,
    stackedCheckboxGroup1: false,
    stackedCheckboxGroup2: true,
    stackedCheckboxGroup3: false,
    inlineCheckboxGroup1: false,
    inlineCheckboxGroup2: false,
    inlineCheckboxGroup3: false,
    // Multi-value checkboxes should be an array of strings
    multiValueCheckboxGroup: ['Dogs', 'Hamsters'],
    singleSwitch: false,
    stackedSwitchGroup1: false,
    stackedSwitchGroup2: true,
    stackedSwitchGroup3: false,
    inlineSwitchGroup1: false,
    inlineSwitchGroup2: false,
    inlineSwitchGroup3: false,
    // Multi-value checkboxes should be an array of strings
    multiValueSwitchGroup: ['Dogs', 'Hamsters']
  };

  const validationSchema = Yup.object().shape({
    singleCheckbox: Yup.bool().oneOf([true], 'You must consent!'),
    stackedCheckboxGroup1: Yup.bool().oneOf([true], 'First option must be checked.'),
    stackedCheckboxGroup2: Yup.bool(),
    stackedCheckboxGroup3: Yup.bool(),
    inlineCheckboxGroup1: Yup.bool(),
    inlineCheckboxGroup2: Yup.bool(),
    inlineCheckboxGroup3: Yup.bool(),
    multiValueCheckboxGroup: Yup.array().of(Yup.string()).required('You must like at least one.'),
    singleSwitch: Yup.bool().oneOf([true], 'You must consent!'),
    stackedSwitchGroup1: Yup.bool().oneOf([true], 'First option must be checked.'),
    stackedSwitchGroup2: Yup.bool(),
    stackedSwitchGroup3: Yup.bool(),
    inlineSwitchGroup1: Yup.bool(),
    inlineSwitchGroup2: Yup.bool(),
    inlineSwitchGroup3: Yup.bool(),
    multiValueSwitchGroup: Yup.array().of(Yup.string()).required('You must like at least one.')
  });

  return (
    <DemoContainer name="FormikCheckboxGroup" section="Forms" componentLinks={componentLinks}>
      <ComponentLayout
        description={<p>
          <code>FormikCheckboxGroup</code> should be used if the user can select more than one option.
        </p>}
        exampleTitle="single, stacked, and inline"
        componentCodeToRender={checkboxCode}
        componentDefaultPropsToRender={checkboxDefaultProps}
        componentPropsToRender={checkboxProps}>
        <Formik initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  // Normally this would be an API call to a server.
                  setTimeout(() => {
                    alert(JSON.stringify(values, null, 2));
                    actions.setSubmitting(false);
                  }, 250);
                }}>
          {formikProps => {
            return (
              <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
                <Row>
                  <Col xs="12">
                    <Card>
                      <CardBody>
                        <FormikCheckboxGroup labelText="Inline Checkbox Group"
                                             inline
                                             checkboxes={[
                                               {name: 'inlineCheckboxGroup1', labelText: 'Inline Checkbox 1'},
                                               {name: 'inlineCheckboxGroup2', labelText: 'Inline Checkbox 2'},
                                               {name: 'inlineCheckboxGroup3', labelText: 'Inline Checkbox 3'}
                                             ]}/>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs="6" className="row-eq-height">
                    <Card className="h-100">
                      <CardBody>
                        <FormikCheckboxGroup labelText="Single Checkbox"
                                             checkboxes={[
                                               {name: 'singleCheckbox', labelText: 'Single Checkbox'}
                                             ]}/>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xs="6">
                    <Card>
                      <CardBody>
                        <FormikCheckboxGroup labelText="Stacked Checkbox Group"
                                             checkboxes={[
                                               {name: 'stackedCheckboxGroup1', labelText: 'Stacked Checkbox 1'},
                                               {name: 'stackedCheckboxGroup2', labelText: 'Stacked Checkbox 2'},
                                               {
                                                 name: 'stackedCheckboxGroup3',
                                                 labelText: 'Stacked Checkbox 3',
                                                 onChange: (e) => {
                                                   console.log('This how to handle an additional onChange event', e.target.checked);
                                                   // do whatever you like with the value, but you need to call formik so the form change happens
                                                   formikProps.handleChange(e);
                                                 },
                                                 noFormikOnChange: true
                                               }
                                             ]}/>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs="12">
                    <Card>
                      <CardBody>
                        <FormikCheckboxGroup labelText="Multi-value Checkbox Group"
                                             inline
                                             checkboxes={[
                                               {name: 'multiValueCheckboxGroup', labelText: 'Dogs', value: 'Dogs'},
                                               {name: 'multiValueCheckboxGroup', labelText: 'Cats', value: 'Cats'},
                                               {
                                                 name: 'multiValueCheckboxGroup',
                                                 labelText: 'Hamsters',
                                                 value: 'Hamsters'
                                               }
                                             ]}/>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <p className="mt-2">
                  <code>FormikCheckboxGroup</code> can also be render as a switch by passing <code>type="switch"</code>
                  as a prop. This should be used to toggle an input between two values.
                </p>
                <Row>
                  <Col xs="12">
                    <Card>
                      <CardBody>
                        <FormikCheckboxGroup labelText="Inline Switch Group"
                                             type="switch"
                                             inline
                                             checkboxes={[
                                               {name: 'inlineSwitchGroup1', labelText: 'Inline Switch 1'},
                                               {name: 'inlineSwitchGroup2', labelText: 'Inline Switch 2'},
                                               {
                                                 name: 'inlineSwitchGroup3',
                                                 labelText: 'Inline Switch 3',
                                                 onChange: (e) => {
                                                   console.log('This how to handle an additional onChange event', e.target.checked);
                                                   // do whatever you like with the value, but you need to call formik so the form change happens
                                                 }
                                               }
                                             ]}/>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs="6">
                    <Card>
                      <CardBody>
                        <FormikCheckboxGroup type="switch"
                                             checkboxes={[
                                               {
                                                 name: 'singleSwitch',
                                                 labelText: 'I will write good comments in my code.'
                                               }
                                             ]}/>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xs="6">
                    <Card>
                      <CardBody>
                        <FormikCheckboxGroup labelText="Stacked Switch Group"
                                             type="switch"
                                             checkboxes={[
                                               {name: 'stackedSwitchGroup1', labelText: 'Stacked Switch 1'},
                                               {name: 'stackedSwitchGroup2', labelText: 'Stacked Switch 2'},
                                               {name: 'stackedSwitchGroup3', labelText: 'Stacked Switch 3'}
                                             ]}/>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs="12">
                    <Card>
                      <CardBody>
                        <FormikCheckboxGroup labelText="Multi-value Checkbox Group"
                                             type="switch"
                                             inline
                                             checkboxes={[
                                               {name: 'multiValueSwitchGroup', labelText: 'Dogs', value: 'Dogs'},
                                               {name: 'multiValueSwitchGroup', labelText: 'Cats', value: 'Cats'},
                                               {name: 'multiValueSwitchGroup', labelText: 'Hamsters', value: 'Hamsters'}
                                             ]}/>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col className="d-flex justify-content-end">
                    <Button type="submit" onClick={formikProps.submitForm}>Submit</Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </ComponentLayout>
    </DemoContainer>
  );
};

export default FormikCheckboxGroupExample;
