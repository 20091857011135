import {useState} from 'react';
import {Button, Col, Row} from 'reactstrap';
import * as Yup from 'yup';

import {FormikInput, FormikModal, FormikPasswordInput} from '@reasoncorp/kyber-js';

import {ComponentLayout, DemoContainer} from '../../components';
import componentLinks from './componentLinks';

const exampleCode = `import {useState} from 'react';
import {Col, Row} from 'reactstrap';

import {FormikModal, FormikInput, FormikPasswordInput} from '@reasoncorp/kyber-js';

const FormikModalExample = () => {
  const [isOpen, setIsOpen] = useState(false);
  const initialValues = {
    email: '',
    password: ''
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required()
  });

  return <FormikModal isOpen={isOpen}
                      size="lg"
                      title="FormikModal"
                      validationSchema={validationSchema}
                      initialValues={initialValues}
                      onSubmit={(values, actions) => {
                        setTimeout(() => {
                          setIsOpen(false);
                          actions.setSubmitting(false);
                          actions.resetForm();
                          alert(JSON.stringify(values, null, 4));
                        }, 1500);
                      }}
                      onCancel={(actions) => {
                        setIsOpen(false);
                        actions.setSubmitting(false);
                        actions.resetForm();
                      }}>
    <Row>
      <Col>
        <FormikInput name="email" 
                     labelText="Email" 
                     autoFocus/>
      </Col>
    </Row>
    <Row>
      <Col>
        <FormikPasswordInput name="password"
                             labelText="Password"/>
      </Col>
    </Row>
  </FormikModal>;
};

export default FormikModalExample;`;

const componentProperties = [
  {'name': 'autoFocus?', type: 'boolean', required: false, defaultValue: 'false'},
  {'name': 'isOpen', type: 'boolean'},
  {'name': 'title', type: 'string', required: false},
  {'name': 'size', type: 'BootstrapModalSize', required: false},
  {'name': 'showCloseIcon', type: 'boolean', required: false, defaultValue: 'true'},
  {'name': 'submitButtonText', type: 'string', required: false, defaultValue: 'Submit'},
  {'name': 'submitButtonColor', type: 'BootstrapColor', required: false, defaultValue: 'success'},
  {'name': 'submitDisabled', type: 'boolean', required: false},
  {'name': 'cancelButtonText', type: 'string', required: false, defaultValue: 'Cancel'},
  {'name': 'cancelButtonColor', type: 'BootstrapColor', required: false, defaultValue: 'secondary'},
  {'name': 'cancelDisabled', type: 'boolean', required: false},
  {'name': 'onCancel', type: '(formikProps: FormikProps<any>) => void'},
  {'name': 'children', type: 'ReactNode'},
  {'name': 'initialValues', type: 'object'},
  {'name': 'validationSchema', type: 'BaseSchema'},
  {'name': 'onSubmit', type: '(values: FormikValues, actions: FormikHelpers<any>) => void'}
];

const FormikModalExample = () => {
  const [isOpen, setIsOpen] = useState(false);
  const initialValues = {
    email: '',
    password: ''
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required()
  });

  return (
    <DemoContainer name="FormikModal" section="Components" componentLinks={componentLinks}>
      <ComponentLayout description={
        <p>
          FormikModal allows a developer to eliminate boilerplate code used to initialize a modal containing a Formik form.
        </p>
      }
                       exampleTitle="FormikModal"
                       componentCodeToRender={exampleCode}
                       componentProps={componentProperties}>
        <>
          <Button color="primary" onClick={() => setIsOpen(true)}>Open FormikModal</Button>
          <FormikModal isOpen={isOpen}
                       size="lg"
                       title="FormikModal"
                       validationSchema={validationSchema}
                       initialValues={initialValues}
                       onSubmit={(values, actions) => {
                         setTimeout(() => {
                           setIsOpen(false);
                           actions.setSubmitting(false);
                           actions.resetForm();
                           alert(JSON.stringify(values, null, 4));
                         }, 1500);
                       }}
                       onCancel={(actions) => {
                         setIsOpen(false);
                         actions.setSubmitting(false);
                         actions.resetForm();
                       }}>
            <Row>
              <Col>
                <FormikInput name="email"
                             labelText="Email"
                             autoFocus/>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormikPasswordInput name="password"
                                     labelText="Password"/>
              </Col>
            </Row>
          </FormikModal>
        </>
      </ComponentLayout>
    </DemoContainer>
  );
};

export default FormikModalExample;
