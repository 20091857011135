import {ComponentLayout, DemoContainer} from '../../components';
import componentLinks from './componentLinks';

const exampleCode = `import {configureRaygun} from '@reasoncorp/kyber-js';

// inside main index.ts of application
import {version} from '../package.json';

const raygunOptions = {
  apiKey: process.env.REACT_APP_RAYGUN_API_KEY || 'apikeygoeshere',
  version: version,
  tags: [config.envName]
};

configureRaygun(raygunOptions);`;

const RaygunExample = () =>
  <DemoContainer name="configureRaygun" section="Utilities" componentLinks={componentLinks}>
    <ComponentLayout description="React Kyber comes with a function used to configure Raygun error reporting for applications."
                     componentCodeToRender={exampleCode}/>
  </DemoContainer>;

export default RaygunExample;