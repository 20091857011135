import {Button, Card, CardBody, CardHeader, Col, Form, Row} from 'reactstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';

import {FormikPhoneInput, YupPhone} from '@reasoncorp/kyber-js';

import {ComponentLayout, DemoContainer} from '../../components';
import {inputPropsTypeCode} from '../../sharedExampleCode';
import componentLinks from './componentLinks';

const formCode = `import {Button, Card, CardBody, Col, Form, Row} from 'reactstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';

import {FormikPhoneInput} from '@reasoncorp/kyber-js';

const PhoneInputExample = () => {
  const initialValues = {
    phone: '(900) 867-5309',
    phoneExtension: ''
  };

  const validationSchema = Yup.object().shape({
    phone: new YupPhone().schema().required('Phone number is required.'),
    phoneExtension: new YupPhone().schema().required('Phone number is required.')
  });

  return (
    <Formik initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              // Normally this would be an API call to a server.
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
                actions.setSubmitting(false);
              }, 250);
            }}>
      {formikProps => {
        return (
          <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <FormikPhoneInput
                      name="phone"
                      labelText="Custom Phone Input"/>
                    <FormikPhoneInput
                      name="phoneExtension"
                      extensionLength={3}
                      labelText="Custom Phone Input with 3 ext chars"/>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="d-flex justify-content-end">
                <Button type="submit" onClick={formikProps.submitForm}>Submit</Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PhoneInputExample;`;

const formikPhoneInputPropTypes = inputPropsTypeCode.inputPropsType + `\n\ntype Props = InputProps & {
  name: string
  extensionLength?: number
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, formikActions: FieldHelperProps<any>) => void
}`;

const FormikPhoneInputExample = () => {
  const initialValues = {
    phone: '(900) 867-5309',
    phoneExtension: ''
  };

  const validationSchema = Yup.object().shape({
    phone: new YupPhone().schema().required('Phone number is required.'),
    phoneExtension: new YupPhone().schema().required('Phone number is required.')
  });

  return (
    <DemoContainer name="FormikPhoneInput" section="Forms" componentLinks={componentLinks}>
      <ComponentLayout
        description={<>
          <p>
            <code>FormikPhoneInput</code> handles auto-formatting phone number input values.
          </p>
          <p>
            <code>FormikPhoneInput</code> wraps <code>FormikInput</code>, so all prop types are supported except for
            &nbsp;<code>type</code>, and <code>noFormikOnChange</code>.
          </p>
          <p>
            Phone numbers with extensions are supported by passing an <code>extensionLength</code> prop.
          </p>
        </>
        }
        componentCodeToRender={formCode}
        componentPropsToRender={formikPhoneInputPropTypes}>
        <Formik initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  // Normally this would be an API call to a server.
                  setTimeout(() => {
                    alert(JSON.stringify(values, null, 2));
                    actions.setSubmitting(false);
                  }, 250);
                }}>
          {formikProps => {
            return (
              <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
                <Row>
                  <Col xs="12">
                    <Card>
                      <CardHeader>
                        FormikPhoneInput example
                      </CardHeader>
                      <CardBody>
                        <FormikPhoneInput name="phone"
                                          labelText="Custom Phone Input"/>
                        <FormikPhoneInput name="phoneExtension"
                                          extensionLength={3}
                                          labelText="Custom Phone Input with 3 ext chars"/>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col className="d-flex justify-content-end">
                    <Button type="submit" onClick={formikProps.submitForm}>Submit</Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </ComponentLayout>
    </DemoContainer>
  );
};

export default FormikPhoneInputExample;
