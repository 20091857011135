import {ReactNode} from 'react';

import {CustomTable} from '@reasoncorp/kyber-js';

import Code from './Code';

type ComponentProp = {
  name: string
  type: string
  defaultValue?: string
  required?: boolean
}

type Props = {
  children?: ReactNode
  componentCodeToRender?: string
  componentPropsToRender?: string
  componentDefaultPropsToRender?: string
  componentProps?: ComponentProp[]
  description?: ReactNode
  exampleTitle?: string
}

const ComponentLayout = ({
                           children,
                           componentCodeToRender,
                           componentPropsToRender,
                           componentDefaultPropsToRender,
                           description,
                           componentProps = [],
                           exampleTitle
                         }: Props) => {
  const tableProps = {
    headers: [
      {title: 'Name'},
      {title: 'Type'},
      {title: 'Default Value'},
      {title: 'Required'}
    ],
    items: componentProps,
    renderRow: ({
                  name,
                  type,
                  defaultValue,
                  required = true
                }: ComponentProp) => {
      return <tr>
        <td>
          {name}
        </td>
        <td>
          <code>{type}</code>
        </td>
        <td>
          {defaultValue}
        </td>
        <td>
          {required ? 'true' : 'false'}
        </td>
      </tr>;
    }
  };

  return (
    <>
      {description && description}
      {exampleTitle && <h2 className="figure-caption text-left">Example: {exampleTitle}</h2>}
      {children}
      {componentCodeToRender && <Code code={componentCodeToRender}/>}
      {componentProps && componentProps.length > 0 &&
        <>
          <h2 className="h4 mt-4">Component Properties</h2>
          <CustomTable {...tableProps} />
        </>
      }
      {componentPropsToRender &&
        <>
          <h2 className="h4 mt-4">Component Properties</h2>
          <Code code={componentPropsToRender}/>
        </>
      }
      {componentDefaultPropsToRender &&
        <>
          <h2 className="h4 mt-4">Default Component Properties</h2>
          <Code code={componentDefaultPropsToRender}/>
        </>
      }
    </>
  );
};

export default ComponentLayout;
