import {Button, Card, CardBody, Col, Form, Row} from 'reactstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';

import {FormikDecimalInput} from '@reasoncorp/kyber-js';

import {ComponentLayout, DemoContainer} from '../../components';
import {inputPropsTypeCode} from '../../sharedExampleCode';
import componentLinks from './componentLinks';

const formCode = `import {Button, Card, CardBody, Col, Form, Row} from 'reactstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';

import {FormikDecimalInput} from '@reasoncorp/kyber-js';

const DecimalInputExample = () => {
  const initialValues = {
    decimal: 1.003,
    decimal2: 99.339,
    decimalNegatives: '-13.00',
    decimal4: 1000000
  };

  const validationSchema = Yup.object().shape({
    decimal: Yup.number().typeError('Must be a decimal.')
      .max(9.999, 'Must be less than 9.999'),
    decimal2: Yup.number().typeError('Must be a decimal.')
      .max(99.999, 'Must be less than 99.999'),
    decimalNegatives: Yup.number()
      .typeError('Must be a decimal.')
      .min(-15, 'Must be greater than -15.00')
      .max(15, 'Must be less than 15.00'),
    decimal4: Yup.number()
      .typeError('Must be a decimal.')
      .min(0, 'Must be greater than 0')
      .max(1000000.00, 'Must be less than 1,000,000.00')
  });

  return (
    <Formik initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              // Normally this would be an API call to a server.
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
                actions.setSubmitting(false);
              }, 250);
            }}>
      {formikProps => {
        return (
          <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <FormikDecimalInput
                      name="decimal"
                      integerPlaces="1"
                      decimalPlaces="3"
                      labelText="Custom Decimal Input - 1 integer place 3 decimal"
                    />
                    <FormikDecimalInput
                      name="decimal2"
                      integerPlaces="2"
                      decimalPlaces="3"
                      labelText="Custom Decimal Input 2 - 2 integer places, 3 decimal"
                    />
                    <FormikDecimalInput
                      name="decimalNegatives"
                      integerPlaces="2"
                      decimalPlaces="2"
                      allowNegatives={true}
                      labelText="Custom Decimal Input 3 - Allows negatives, 2 integer places, 2 decimal"
                    />
                    <FormikDecimalInput
                      name="decimal4"
                      integerPlaces="7"
                      decimalPlaces="2"
                      labelText="Custom Decimal Input 4 -- 7 integer places, 2 decimal"/>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="d-flex justify-content-end">
                <Button type="submit" onClick={formikProps.submitForm}>Submit</Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DecimalInputExample;`;

const formikDecimalInputPropTypes = inputPropsTypeCode.inputPropsType + `\n\ntype Props = InputProps & {
  name: string
  allowNegatives?: boolean
  integerPlaces: string | number
  decimalPlaces: string | number
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, fieldActions: FieldHelperProps<any>) => void
}`;

const FormikDecimalInputExample = () => {
  const initialValues = {
    decimal: 1.003,
    decimal2: 99.339,
    decimalNegatives: '-13.00',
    decimal4: 1000000
  };

  const validationSchema = Yup.object().shape({
    decimal: Yup.number().typeError('Must be a decimal.')
      .max(9.999, 'Must be less than 9.999'),
    decimal2: Yup.number().typeError('Must be a decimal.')
      .max(99.999, 'Must be less than 99.999'),
    decimalNegatives: Yup.number()
      .typeError('Must be a decimal.')
      .min(-15, 'Must be greater than -15.00')
      .max(15, 'Must be less than 15.00'),
    decimal4: Yup.number()
      .typeError('Must be a decimal.')
      .min(0, 'Must be greater than 0')
      .max(1000000.00, 'Must be less than 1,000,000.00')
  });

  return (
    <DemoContainer name="FormikDecimalInput" section="Forms" componentLinks={componentLinks}>
      <ComponentLayout
        description={<>
          <p>
            <code>FormikDecimalInput</code> handles auto-formatting decimal input values.
          </p>
          <p>
            <code>FormikDecimalInput</code> wraps <code>FormikInput</code>, so all prop types are supported except for
            &nbsp;
            <code>type</code>, <code>onBlur</code>, <code>onKeyUp</code>, <code>maxLength</code>
            &nbsp;and <code>noFormikOnChange</code>.
          </p>
          <p>
            To specify the allowed numbers of integer places and decimal places, pass <code>integerPlaces</code> and
            &nbsp;
            <code>decimalPlaces</code> props to <code>FormikDecimalInput</code>. Both props are required for proper
            &nbsp;functionality of the component.
          </p>
          <p>
            <code>FormikDecimalInput</code> auto-calculates <code>maxLength</code>
            &nbsp;based on the provided <code>integerPlaces</code>, <code>decimalPlaces</code>, and <code>allowNegatives</code>
            &nbsp;props.
          </p>
          <p>
            Negative values are supported by setting the <code>allowNegatives</code> prop to <code>true</code>.
          </p>
        </>
        }
        exampleTitle="decimal input"
        componentCodeToRender={formCode}
        componentPropsToRender={formikDecimalInputPropTypes}>
        <Formik initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  // Normally this would be an API call to a server.
                  setTimeout(() => {
                    alert(JSON.stringify(values, null, 2));
                    actions.setSubmitting(false);
                  }, 250);
                }}>
          {formikProps => {
            return (
              <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
                <Row>
                  <Col xs="12">
                    <Card>
                      <CardBody>
                        <FormikDecimalInput
                          name="decimal"
                          integerPlaces="1"
                          decimalPlaces="3"
                          labelText="Custom Decimal Input - 1 integer place 3 decimal"
                        />
                        <FormikDecimalInput
                          name="decimal2"
                          integerPlaces="2"
                          decimalPlaces="3"
                          labelText="Custom Decimal Input 2 - 2 integer places, 3 decimal"
                        />
                        <FormikDecimalInput
                          name="decimalNegatives"
                          integerPlaces="2"
                          decimalPlaces="2"
                          allowNegatives={true}
                          labelText="Custom Decimal Input 3 - Allows negatives, 2 integer places, 2 decimal"
                        />
                        <FormikDecimalInput
                          name="decimal4"
                          integerPlaces="7"
                          decimalPlaces="2"
                          labelText="Custom Decimal Input 4 -- 7 integer places, 2 decimal"/>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col className="d-flex justify-content-end">
                    <Button type="submit" onClick={formikProps.submitForm}>Submit</Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </ComponentLayout>
    </DemoContainer>
  );
};

export default FormikDecimalInputExample;
