import {Button, Card, CardBody, Col, Form, Row} from 'reactstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';

import {FormikPasswordInput} from '@reasoncorp/kyber-js';

import {ComponentLayout, DemoContainer} from '../../components';
import componentLinks from './componentLinks';
import {bootStrapTypeCode} from '../../sharedExampleCode';

const formCode = `import {Button, Card, CardBody, Col, Form, Row} from 'reactstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';

import {FormikPasswordInput} from '@reasoncorp/kyber-js';

const Example = () => {
  const initialValues = {
    password: ''
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string().required('Required')
  });

  return (
    <>
      <Formik initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                // Normally this would be an API call to a server.
                setTimeout(() => {
                  alert(JSON.stringify(values, null, 2));
                  actions.setSubmitting(false);
                }, 250);
              }}>
        {formikProps => {
          return (
            <Form
              onSubmit={formikProps.handleSubmit}
              autoComplete="off">
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <FormikPasswordInput name="password" labelText="Password"/>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="d-flex justify-content-end">
                  <Button type="submit" onSubmit={formikProps.submitForm}>
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default Example;`;

const passwordInputProps = bootStrapTypeCode.bootstrapFormControlSizeCode + `\n\ntype Props = {
  [key: string]: any
  id?: string
  name: string
  bsSize?: BootstrapFormControlSize
  formGroupClass?: string
  labelText?: string
  disableFloatingLabel?: boolean
  ariaLabel?: string
  disabled?: boolean
  disableOnSubmit?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, fieldActions: FieldHelperProps<any>) => void
  noFormikOnChange?: boolean
  onBlur?: (e: React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLDivElement>, fieldActions: FieldHelperProps<any>) => void
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>, fieldActions: FieldHelperProps<any>) => void
}`;

const passwordInputDefaultProps = `{
  disableFloatingLabel: false,
  disableOnSubmit: false
}`;

const FormikPasswordInputExample = () => {
  const initialValues = {
    password: ''
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string().required('Required')
  });

  return (
    <DemoContainer name="FormikPasswordInput" section="Forms" componentLinks={componentLinks}>
      <ComponentLayout
        description={
          <p>
            <code>FormikPasswordInput</code> should be used for sensitive input fields.
            A toggle icon for showing and hiding the field's value is displayed to the right of the input to allow a user
            to verify the data is correct.
          </p>
        }
        exampleTitle="password input"
        componentDefaultPropsToRender={passwordInputDefaultProps}
        componentPropsToRender={passwordInputProps}
        componentCodeToRender={formCode}>
        <>
          <Formik initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values, actions) => {
                    // Normally this would be an API call to a server.
                    setTimeout(() => {
                      alert(JSON.stringify(values, null, 2));
                      actions.setSubmitting(false);
                    }, 250);
                  }}>
            {formikProps => {
              return (
                <Form
                  onSubmit={formikProps.handleSubmit}
                  autoComplete="off">
                  <Row>
                    <Col xs="12">
                      <Card>
                        <CardBody>
                          <FormikPasswordInput name="password" labelText="Password"/>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col className="d-flex justify-content-end">
                      <Button type="submit" onSubmit={formikProps.submitForm}>
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </>
      </ComponentLayout>
    </DemoContainer>
  );
};

export default FormikPasswordInputExample;
