import {CustomTable} from '@reasoncorp/kyber-js';

import Item from '../Item';
import {ComponentLayout} from '../../../components';

export const exampleCode = `import {CustomTable} from '@reasoncorp/kyber-js';

import Item from '../Item';

const PaginatorExample3 = () => {
  const tableProps = {
    headers: [
      {title: 'ID', sortKey: 'id', className: 'align-middle text-center'},
      {title: 'First Name', sortKey: 'firstName', className: 'align-middle'},
      {title: 'Last Name', sortKey: 'lastName', className: 'align-middle'},
      {title: 'Certification Level', sortKey: 'certificationLevel', className: 'align-middle text-center'},
      {title: 'Certification Number', sortKey: 'certificationNumber', className: 'align-middle text-center'}
    ],
    initialSort: {sortKey: 'lastName', direction: 'asc' as const},
    items: [
      {id: 1, firstName: 'Philip', lastName: 'Calouche', certificationLevel: null, certificationNumber: null},
      {id: 2, firstName: 'Becca', lastName: 'Kinard', certificationLevel: 'MCAO', certificationNumber: 'R-2222'},
      {id: 3, firstName: 'Justin', lastName: 'Baker', certificationLevel: 'MAAO', certificationNumber: 'R-3333'},
      {id: 4, firstName: 'Jesse', lastName: 'West', certificationLevel: 'MMAO', certificationNumber: 'T-4444'}
    ],
    renderRow: (item: Item) => {
      return (
        <tr key={item.id}>
          <td className="align-middle text-center">{item.id}</td>
          <td className="align-middle">{item.firstName}</td>
          <td className="align-middle">{item.lastName}</td>
          <td className="align-middle text-center">{item.certificationLevel}</td>
          <td className="align-middle text-center">{item.certificationNumber}</td>
        </tr>
      );
    },
    paginatorConfig: {
      perPage: 2,
      recordName: 'users',
      allowShowAll: true
    }
  };

  return <CustomTable {...tableProps}/>;
};

export default PaginatorExample3;`;

const PaginatorExample3 = () => {
  const tableProps = {
    headers: [
      {title: 'ID', sortKey: 'id', className: 'align-middle text-center'},
      {title: 'First Name', sortKey: 'firstName', className: 'align-middle'},
      {title: 'Last Name', sortKey: 'lastName', className: 'align-middle'},
      {title: 'Certification Level', sortKey: 'certificationLevel', className: 'align-middle text-center'},
      {title: 'Certification Number', sortKey: 'certificationNumber', className: 'align-middle text-center'}
    ],
    initialSort: {sortKey: 'lastName', direction: 'asc' as const},
    items: [
      {id: 1, firstName: 'Philip', lastName: 'Calouche', certificationLevel: null, certificationNumber: null},
      {id: 2, firstName: 'Becca', lastName: 'Kinard', certificationLevel: 'MCAO', certificationNumber: 'R-2222'},
      {id: 3, firstName: 'Justin', lastName: 'Baker', certificationLevel: 'MAAO', certificationNumber: 'R-3333'},
      {id: 4, firstName: 'Jesse', lastName: 'West', certificationLevel: 'MMAO', certificationNumber: 'T-4444'}
    ],
    renderRow: (item: Item) => {
      return (
        <tr key={item.id}>
          <td className="align-middle text-center">{item.id}</td>
          <td className="align-middle">{item.firstName}</td>
          <td className="align-middle">{item.lastName}</td>
          <td className="align-middle text-center">{item.certificationLevel}</td>
          <td className="align-middle text-center">{item.certificationNumber}</td>
        </tr>
      );
    },
    paginatorConfig: {
      perPage: 2,
      recordName: 'users',
      allowShowAll: true
    }
  };

  return (
    <ComponentLayout exampleTitle="CustomTable with a built in Paginator with Show All functionality."
                     componentCodeToRender={exampleCode}>
      <CustomTable {...tableProps}/>
    </ComponentLayout>
  );
};

export default PaginatorExample3;