import {DemoContainer} from '../../components';
import componentLinks from './componentLinks';
import {ResultsLimiterExample1, ResultsLimiterExample2} from './resultsLimiter';

const ResultsLimiterExample = () => {
  return <DemoContainer name="ResultsLimiter" section="Tables" componentLinks={componentLinks}>
    <p>
      <code>ResultLimiter</code> allows a developer to limit the number of items shown in a table to a specified number.
    </p>
    <hr/>
    <ResultsLimiterExample1/>
    <hr/>
    <ResultsLimiterExample2/>
  </DemoContainer>;
};

export default ResultsLimiterExample;