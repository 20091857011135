import {Button, Card, CardBody, Col, Form, Row} from 'reactstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';

import {County, FormikAutoComplete, LocalUnit} from '@reasoncorp/kyber-js';

import {ComponentLayout, DemoContainer} from '../../components';
import {inputPropsTypeCode} from '../../sharedExampleCode';
import componentLinks from './componentLinks';
import {useEffect, useState} from 'react';
import {restApiHelper} from '../../api/apiUtils';

const autocompleteProps = inputPropsTypeCode.inputPropsType + `\n\ntype AutoCompleteOption = {
  value: any;
  displayValue: string;
}

type Props = InputProps & {
  id?: string
  name: string
  bsSize?: BootstrapFormControlSize
  labelText?: string
  ariaLabel?: string
  icon?: IconProp
  disabled?: boolean
  noFormikOnChange?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  options: AutoCompleteOption[] | string[]
  disableFloatingLabel?: boolean
  caseSensitive?: boolean
  noResultsMessage?: string
  showAllOnBlank?: boolean
  autoFocus?: boolean
}`;

const autocompleteExampleCode = `import {Button, Card, CardBody, Col, Form, Row} from 'reactstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';

import {County, FormikAutoComplete, LocalUnit} from '@reasoncorp/kyber-js';

const AutocompleteExample = () => {
  const [counties, setCounties] = useState<County[]>([]);
  const [localUnits, setLocalUnits] = useState<LocalUnit[]>([]);

  useEffect(() => {
    const loadCounties = async () => {
      const counties: County[] = await restApiHelper.getWithJsonResponse('/counties.json');
      const localUnits: LocalUnit[] = await restApiHelper.getWithJsonResponse('/localUnits.json');
      setCounties(counties);
      setLocalUnits(localUnits);
    };
    loadCounties().then();
  });

  const countyAutoCompleteOptions = counties.map((county) => ({
    value: county.id,
    displayValue: \`\${county.displayNameWithType} - \${county.id}\`
  }));

  const localUnitAutoCompleteOptions = localUnits.map((localUnit) => ({
    value: localUnit.id,
    displayValue: \`\${localUnit.displayNameWithType} - \${localUnit.id}\`
  }));

  const initialValues = {
    county: '',
    localUnit: '',
    countyCaseSensitive: '',
    countyId: '',
    localUnitId: ''
  };

  const validationSchema = Yup.object().shape({
    county: Yup.string(),
    countyCaseSensitive: Yup.string(),
    countyId: Yup.number()
      .typeError(\`County must be one of: \${countyAutoCompleteOptions.join(', ')}.\`),
    localUnit: Yup.string(),
    localUnitId: Yup.number()
      .typeError(\`Local Unit must be one of: \${localUnitAutoCompleteOptions.join(', ')}.\`)
  });

  return (
    <Formik initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
                actions.setSubmitting(false);
              }, 250);
            }}>
      {formikProps => {
        return (
          <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
            <Row>
              <Col xs="6">
                <Card>
                  <CardBody>
                    <FormikAutoComplete name="county"
                                        labelText="County Choices"
                                        options={countyAutoCompleteOptions}/>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="6">
                <Card>
                  <CardBody>
                    <FormikAutoComplete name="localUnit"
                                        labelText="Michigan Local Units"
                                        options={localUnitAutoCompleteOptions}/>
                  </CardBody>
                </Card>
            </Col>
            </Row>
            <Row className="mt-3">
              <Col xs="12">
                <Card>
                  <CardBody>
                    <FormikAutoComplete name="countyCaseSensitive"
                                        caseSensitive={true}
                                        showAllOnBlank={false}
                                        labelText="County Choice (Case Sensitive, disable showAllOnBlank)"
                                        options={countyAutoCompleteOptions}/>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="d-flex justify-content-end">
                <Button onClick={formikProps.submitForm}>Submit</Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  )
};

export default AutocompleteExample;`;

const autocompleteDefaultProps = `{
  caseSensitive: false,
  noResultsMessage: 'No Results',
  showAllOnBlank: true,
  autoFocus: false
}`;

const FormikAutocompleteExample = () => {
  const [counties, setCounties] = useState<County[]>([]);
  const [localUnits, setLocalUnits] = useState<LocalUnit[]>([]);

  useEffect(() => {
    const loadCounties = async () => {
      const counties: County[] = await restApiHelper.getWithJsonResponse('/counties.json');
      const localUnits: LocalUnit[] = await restApiHelper.getWithJsonResponse('/localUnits.json');
      setCounties(counties);
      setLocalUnits(localUnits);
    };
    loadCounties().then();
  });

  const countyAutoCompleteOptions = counties.map((county) => ({
    value: county.id,
    displayValue: `${county.displayNameWithType} - ${county.id}`
  }));

  const localUnitAutoCompleteOptions = localUnits.map((localUnit) => ({
    value: localUnit.id,
    displayValue: `${localUnit.displayNameWithType} - ${localUnit.id}`
  }));

  const initialValues = {
    county: '',
    localUnit: '',
    countyCaseSensitive: '',
    countyId: '',
    localUnitId: ''
  };

  const validationSchema = Yup.object().shape({
    county: Yup.string(),
    countyCaseSensitive: Yup.string(),
    countyId: Yup.number()
      .typeError(`County must be one of: ${countyAutoCompleteOptions.join(', ')}.`),
    localUnit: Yup.string(),
    localUnitId: Yup.number()
      .typeError(`Local Unit must be one of: ${localUnitAutoCompleteOptions.join(', ')}.`)
  });

  return (
    <DemoContainer name="FormikAutoComplete" section="Forms" componentLinks={componentLinks}>
      <ComponentLayout
        description={<p>
          <code>FormikAutoComplete</code> should be used when FormikSelect is unwieldy to use for large lists of options for the user to select from.
        </p>}
        componentCodeToRender={autocompleteExampleCode}
        componentPropsToRender={autocompleteProps}
        componentDefaultPropsToRender={autocompleteDefaultProps}
        exampleTitle="Autocomplete examples">
        <Formik initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  setTimeout(() => {
                    alert(JSON.stringify(values, null, 2));
                    actions.setSubmitting(false);
                  }, 250);
                }}>
          {formikProps => {
            return (
              <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
                <Row clas>
                  <Col xs="6">
                    <Card>
                      <CardBody>
                        <FormikAutoComplete name="county"
                                            labelText="Michigan Counties"
                                            options={countyAutoCompleteOptions}/>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xs="6">
                    <Card>
                      <CardBody>
                        <FormikAutoComplete name="localUnit"
                                            labelText="Michigan Local Units"
                                            options={localUnitAutoCompleteOptions}/>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs="12">
                    <Card>
                      <CardBody>
                        <FormikAutoComplete name="countyCaseSensitive"
                                            caseSensitive={true}
                                            showAllOnBlank={false}
                                            labelText="County Choice (Case Sensitive, disable showAllOnBlank)"
                                            options={countyAutoCompleteOptions}/>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col className="d-flex justify-content-end">
                    <Button onClick={formikProps.submitForm}>Submit</Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </ComponentLayout>
    </DemoContainer>
  );
};

export default FormikAutocompleteExample;
