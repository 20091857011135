import {bootstrapFormControlSizeCode} from './bootStrapTypeCode';

export const inputPropsType = bootstrapFormControlSizeCode + `\n\ntype InputProps = {
  [key: string]: any
  id?: string
  name: string
  type?: 'text' | 'textarea' | 'file'
  bsSize?: BootstrapFormControlSize
  formGroupClass?: string
  labelText?: string
  disableFloatingLabel?: boolean
  ariaLabel?: string
  icon?: {
    name?: string
    text?: string
  }
  disabled?: boolean
  disableOnSubmit?: boolean // Form components set this to true if not specified
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, fieldActions: FieldHelperProps<any>) => void
  noFormikOnChange?: boolean
  onBlur?: (e: React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLDivElement>, fieldActions: FieldHelperProps<any>) => void
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>, fieldActions: FieldHelperProps<any>) => void
  format?: (value: any) => any
}`;