import {useState} from 'react';
import {Button} from 'reactstrap';

import {AlertModal} from '@reasoncorp/kyber-js';

import {ComponentLayout, DemoContainer} from '../../components';
import componentLinks from './componentLinks';

const exampleCode = `import {useState} from 'react'; 
import {Button} from 'reactstrap';

import {AlertModal} from '@reasoncorp/kyber-js';

const AlertModalExample  = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleConfirmation = () => {
    setIsOpen(false);
    // Code to do whatever is required after confirmation...
  };

  return (
    <div>
      <Button color="primary" onClick={() => setIsOpen(true)}>Launch Alert Modal</Button>
      <AlertModal isOpen={isOpen}
                  size="lg"
                  title="Delete databases"
                  confirmButtonText="Ok"
                  confirmCallback={handleConfirmation}>
        <p>Are you sure you want to delete all MiSuite production databases?</p>
      </AlertModal>
    </div>
  );
};

export default AlertModalExample;`;

const componentProps = [
  {name: 'isOpen', type: 'boolean'},
  {name: 'title', type: 'string', required: false},
  {name: 'size', type: 'BootstrapModalSize', required: false},
  {name: 'confirmButtonText', type: 'string', required: false, defaultValue: 'Ok'},
  {name: 'confirmButtonColor', type: 'BootstrapColor', required: false, defaultValue: 'success'},
  {name: 'confirmCallback', type: `(e: React.MouseEvent<HTMLButtonElement>) => void`},
  {name: 'children', type: 'ReactNode'}
];

const AlertModalExample = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleConfirmation = () => {
    setIsOpen(false);
    // Code to do whatever is required after confirmation...
  };

  return (
    <DemoContainer name="AlertModal" section="Components" componentLinks={componentLinks}>
      <ComponentLayout description={
        <p>
          An alert modal, or dialog, asks a user to read an alert before moving on.
          It has no cancel or close buttons.
        </p>
      }
                       exampleTitle="Alert Modal"
                       componentCodeToRender={exampleCode}
                       componentProps={componentProps}>
        <div>
          <Button color="primary" onClick={() => setIsOpen(true)}>Launch Alert Modal</Button>
          <AlertModal isOpen={isOpen}
                      size="lg"
                      title="Delete databases"
                      confirmButtonText="Ok"
                      confirmCallback={handleConfirmation}>
            <p>Are you sure you want to delete all MiSuite production databases?</p>
          </AlertModal>
        </div>
      </ComponentLayout>
    </DemoContainer>
  );
};

export default AlertModalExample;
