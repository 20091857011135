import {ReactNode} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Col, Container, Row} from 'reactstrap';

type Props = {
  name: string
  section?: string
  children: ReactNode
  componentLinks?: ComponentLink[]
};

type ComponentLink = {
  path: string
  name: string
  rootPath?: string
}

const DemoContainer = ({
                         name,
                         section,
                         componentLinks = [],
                         children
                       }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const linkClassName = (componentLink: ComponentLink) => {
    let className = 'pl-3 pt-1 pb-1 display-block w-100 cursor-pointer';
    className += (location.pathname === componentLink.path || location.pathname === componentLink.rootPath) ? ' active text-primary font-weight-bold' : '';
    return className;
  };

  const handleClick = (componentLink: ComponentLink) => {
    navigate(componentLink.path + window.location.search);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <Container className={name + '-example'}>
      <h2 className="text-primary">{name}</h2>
      <hr/>
      <Row className="demo-container-section">
        <Col className="col-md-9 order-md-1 demo-content">
          {children}
        </Col>
        <Col className="col-md-3 order-md-2 demo-sidebar">
          <div className="flex-column sticky-top flex-nowrap vh-100 overflow-auto p-2 pt-4">
            <h2 className="h5 text-primary">{section}</h2>
            <ul className="nav">
              {componentLinks.map((componentLink, index) =>
                <li key={index}
                    className={linkClassName(componentLink)}
                    onClick={() => handleClick(componentLink)}>
                  {componentLink.name}
                </li>
              )}
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default DemoContainer;