import {Button, Card, CardBody, Col, Form, Row} from 'reactstrap';
import {Formik, FormikValues} from 'formik';
import * as Yup from 'yup';

import {FormikFileInput, YupFile} from '@reasoncorp/kyber-js';

import {ComponentLayout, DemoContainer} from '../../components';
import {inputPropsType} from '../../sharedExampleCode/inputPropsTypeCode';
import componentLinks from './componentLinks';

const fileInputCode = `import {Card, CardBody, Col, Form, Row} from 'reactstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';

import {FormikFileInput, YupFile} from '@reasoncorp/kyber-js';

const FileInputExample = () => {
  const initialValues = {
    file1: null,
    file2: null,
    file3: null
  };

  const validationSchema = Yup.object().shape({
    file1: new YupFile().maxFileSize(10000, 'Must be less than 1mb').schema(),
    file2: new YupFile().schema().required('required'),
    file3: new YupFile()
                  .acceptedFileTypes(['CSV'], 'must be a csv file')
                  .schema()
                  .required('required')
  });

  return (
    <Formik initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values: FormikValues, actions) => {
              // Normally this would be an API call to a server
              setTimeout(() => {
                alert(JSON.stringify({
                  file1: values?.file1?.name,
                  file2: values?.file2?.name,
                  file3: values?.file3?.name
                }, null, 2));
                actions.setSubmitting(false);
              }, 250);
            }}>
      {formikProps => {
        return (
          <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <FormikFileInput name="file1" labelText="File Upload"/>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="d-flex justify-content-end">
                <Button type="submit" onClick={formikProps.submitForm}>Submit</Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FileInputExample;`;

const fileInputProps = inputPropsType + `\n\ntype Props = InputProps & {
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}`;

const FormikFileInputExample = () => {
  const initialValues = {
    file1: null,
    file2: null,
    file3: null
  };

  const validationSchema = Yup.object().shape({
    file1: new YupFile()
      .maxFileSize(10000, 'Must be less than 1mb')
      .schema(),
    file2: new YupFile()
      .schema()
      .required('required'),
    file3: new YupFile()
      .acceptedFileTypes(['CSV'], 'must be a csv file')
      .schema()
      .required('required')
  });

  return (
    <DemoContainer name="FormikFileInput" section="Forms" componentLinks={componentLinks}>
      <ComponentLayout
        description={<>
          <p>
            The file input should be used when a form requires a file to be uploaded.
          </p>
          <p>
            <code>FormikFileInput</code> wraps <code>FormikInput</code>, so all prop types are supported except for
            &nbsp;<code>type</code> and <code>noFormikOnChange</code>.
          </p>
        </>}
        exampleTitle="file input"
        componentCodeToRender={fileInputCode}
        componentPropsToRender={fileInputProps}>
        <Formik initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values: FormikValues, actions) => {
                  // Normally this would be an API call to a server
                  setTimeout(() => {
                    alert(JSON.stringify({
                      file1: values?.file1?.name,
                      file2: values?.file2?.name,
                      file3: values?.file3?.name
                    }, null, 2));
                    actions.setSubmitting(false);
                  }, 250);
                }}>
          {formikProps => {
            return (
              <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        <FormikFileInput name="file1" labelText="File Upload"/>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col className="d-flex justify-content-end">
                    <Button type="submit" onClick={formikProps.submitForm}>Submit</Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </ComponentLayout>
    </DemoContainer>
  );
};

export default FormikFileInputExample;
