import {ComponentLayout, DemoContainer} from '../../components';
import componentLinks from './componentLinks';

const exampleCode = `import * as Yup from 'yup';

// Create a new instance of YupFileArray to use
// .schema() must be called after maxFileSize and acceptedFileTypes in order to return the actual yup schema instance.
const schema = new YupFileArray().schema();

// use maxFileSize() to validate file sizes
const schema = new YupFileArray().maxFileSize(1024 * 1024, "Max file size is 1mb!").schema();

// use acceptedFileTypes() to validate file MIME types
const schema = new YupFileArray().acceptedFileTypes(["CSV"], "Invalid file type, must be .csv").schema();

// Currently only the following aliases are supported for acceptedFileTypes.
// These are aliased to the corresponding MIME type of the file type.
// PDF, PNG, JPG, JPEG, DO', DOCX, TXT,  XLS, XLSX, XLSM, and CSV
// You may also pass a custom MIME type to validate
const schema = new YupFileArray().acceptedFileTypes(["application/js"], "Invalid file type, must be .js").schema();

// all validation methods of Yup.array() are supported
const schema = new YupFileArray().schema().min(2, "Must upload at least two files");
`;

const YupFileArrayExample = () =>
  <DemoContainer name="YupFileArray" section="Utilities" componentLinks={componentLinks}>
    <ComponentLayout description="A custom Yup schema for handling validation of arrays of files."
                     componentCodeToRender={exampleCode}/>
  </DemoContainer>;

export default YupFileArrayExample;