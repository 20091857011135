import {Button, Card, CardBody, CardHeader, Col, Row} from 'reactstrap';

import {ComponentLayout, DemoContainer} from '../../components';
import componentLinks from './componentLinks';

import {TabNav, TabNavContent, useTabNav, withTabNav} from '@reasoncorp/kyber-js';

const tabNavCode = `import {Button, Card, CardBody, CardHeader, Col, Row} from 'reactstrap';

import {TabNavContent, TabNav, useTabNav, withTabNav} from '@reasoncorp/kyber-js';

const TavNavExample = () => {
  const {selectedTab, setSelectedTab, setTabDisplayValue, setTabs} = useTabNav();
  
  const handleUpdateTabs = () => {
    setTabs({
      tabs: [
        {value: 'mx250', displayValue: '250 MX'},
        {value: 'mx450', displayValue: '450 MX'}
      ]
    });
  };
  
  return <>
          <Row>
            <Col>
              <Card>
                <CardHeader className="bg-secondary text-white">
                  Tab Nav Example
                </CardHeader>
                <CardHeader className="bg-white">
                  <Row className="d-flex justify-content-between">
                    <Col className="col-6 pt-2">
                      <TabNav/>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <TabNavContent tab="sx450">
                    Eli Tomac is leading the championship!
                  </TabNavContent>
                  <TabNavContent tab="sx250east">
                    250 East Coast Supercross has yet to begin
                  </TabNavContent>
                  <TabNavContent tab="sx250west">
                    Jett Lawrence is leading the championship!
                  </TabNavContent>
                  
                  <TabNavContent tab="mx450">
                    Who will it be this year now that Jett Lawrence is moving up to 450s for the outdoor season? Should be an exciting season!
                  </TabNavContent>

                  <TabNavContent tab="mx250">
                    With Jett Lawrence moving up the 250 championship is up for grabs!
                  </TabNavContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mt-3 mb-3">
            <Col>
            <Button color="primary"
                      onClick={() => setSelectedTab('sx450')}>
                Set to 450 SX
              </Button>
              <Button color="primary"
                      className="ml-2"
                      onClick={() => setTabDisplayValue('sx450', 'Tomac wins 450s')}>
                Update 450sx display value
              </Button>
              <Button color="primary"
                      className="ml-2"
                      onClick={() => handleUpdateTabs()}>
                Update tabs for MX season
              </Button>
            </Col>
          </Row>
           <Row className="mb-3">
             <Col>
               <strong>Currently Selected Tab is: {selectedTab}</strong>
             </Col>
           </Row>
        </>
  };

export default withTabNav(TabNavExample, {
  tabs: [
    {value: 'sx250east', displayValue: '250 East SX'},
    {value: 'sx250west', displayValue: '250 West SX'},
    {value: 'sx450', displayValue: '450 SX'}
  ],
  initialTab: 'sx450'
});`;

const TabNavExample = () => {
  const {selectedTab, setSelectedTab, setTabDisplayValue, setTabs} = useTabNav();

  const handleUpdateTabs = () => {
    setTabs({
      tabs: [
        {value: 'mx250', displayValue: '250 MX'},
        {value: 'mx450', displayValue: '450 MX'}
      ]
    });
  };

  return (
    <DemoContainer name="TabNav" section="Components" componentLinks={componentLinks}>
      <ComponentLayout description={<>
        <p>
          A <code>TabNav</code> is used to separate categorized content for display. You must wrap a component with the higher order component
          &nbsp;
          <code>withTabNav</code>, providing the wrapped component as the first argument, and an object of options with <code>tabs</code>
          &nbsp;and (optionally) <code>initialTab</code> properties.
        </p>
        <p>
          You may utilize properties of the <code>TabNav</code> such as retrieving the currently selected tab&nbsp;
          <code>selectedTab</code>
          , changing the currently selected tab&nbsp;<code>setSelectedTab</code>, changing
          the display value for a tab&nbsp;<code>setTabDisplayValue</code>, and updating the available tabs&nbsp;
          <code>setTabs</code> by including <code>useTabNav</code> inside the wrapped component.
        </p>
        <p>
          Tab content for the selected tab within the wrapped component can be contained inside a <code>TabNavContent</code> component,
          passing the tab value as a prop <code>tab</code>.
        </p>
      </>}
                       exampleTitle="TabNav - selected initial tab"
                       componentCodeToRender={tabNavCode}>
        <>
          <Row>
            <Col>
              <Card>
                <CardHeader className="bg-secondary text-white">
                  Tab Nav Example
                </CardHeader>
                <CardHeader className="bg-white">
                  <Row className="d-flex justify-content-between">
                    <Col className="col-6 pt-2">
                      <TabNav/>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <TabNavContent tab="sx450">
                    Eli Tomac is leading the championship!
                  </TabNavContent>

                  <TabNavContent tab="sx250east">
                    250 East Coast Supercross has yet to begin
                  </TabNavContent>

                  <TabNavContent tab="sx250west">
                    Jett Lawrence is leading the championship!
                  </TabNavContent>

                  <TabNavContent tab="mx450">
                    Who will it be this year now that Jett Lawrence is moving up to 450s for the outdoor season? Should be an exciting season!
                  </TabNavContent>

                  <TabNavContent tab="mx250">
                    With Jett Lawrence moving up the 250 championship is up for grabs!
                  </TabNavContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mt-3 mb-3">
            <Col>
              <Button color="primary"
                      onClick={() => setSelectedTab('sx450')}>
                Set to 450 SX
              </Button>
              <Button color="primary"
                      className="ml-2"
                      onClick={() => setTabDisplayValue('sx450', 'Tomac wins 450s')}>
                Update 450sx display value
              </Button>
              <Button color="primary"
                      className="ml-2"
                      onClick={() => handleUpdateTabs()}>
                Update tabs for MX season
              </Button>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <strong>Currently Selected Tab is: {selectedTab}</strong>
            </Col>
          </Row>
        </>
      </ComponentLayout>
    </DemoContainer>
  );
};

export default withTabNav(TabNavExample, {
  tabs: [
    {value: 'sx250east', displayValue: '250 East SX'},
    {value: 'sx250west', displayValue: '250 West SX'},
    {value: 'sx450', displayValue: '450 SX'}
  ],
  initialTab: 'sx450'
});
