import {Container, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap';

const ThemeSelector = () => {
  const theme = new URLSearchParams(window.location.search).get('theme');
  let dropdownText;

  switch (theme) {
    case 'misuite-v1':
      dropdownText = 'MiSuite V1 Theme';
      import('../scss/index-misuite.scss').then();
      break;
    case 'misuite-v2':
      dropdownText = 'MiSuite V2 Theme';
      import('../scss/index-misuite-v2.scss').then();
      break;
    default:
      dropdownText = 'MiSuite V2 Theme';
      import('../scss/index-misuite-v2.scss').then();
      break;
  }

  const updateTheme = (theme: string) => {
    // Update the search part of the URL which will force a page refresh and reload the page with the new theme
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('theme', theme);
    window.location.search = searchParams.toString();
  };

  return <Container className="d-flex justify-content-end">
    <UncontrolledDropdown className="mb-2">
      <DropdownToggle caret>
        {dropdownText}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => updateTheme('misuite-v2')}
                      disabled={theme === 'misuite-v2' || !theme}>
          MiSuite V2 Theme
        </DropdownItem>
        <DropdownItem onClick={() => updateTheme('misuite-v1')}
                      disabled={theme === 'misuite-v1'}>
          MiSuite V1 Theme
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  </Container>;
};

export default ThemeSelector;