import {Button, Card, CardBody, Col, Form, Row, Table} from 'reactstrap';
import {FieldInputProps, Formik, FormikProps, FormikValues} from 'formik';
import * as Yup from 'yup';

import {ButtonIcon, FormikInput, FormikMultipleFileInput, YupFileArray} from '@reasoncorp/kyber-js';

import {ComponentLayout, DemoContainer} from '../../components';
import {bootStrapTypeCode} from '../../sharedExampleCode';
import componentLinks from './componentLinks';

const fileInputCode = `import {Button, Card, CardBody, Col, Form, Row, Table} from 'reactstrap';
import {FieldInputProps, Formik, FormikProps, FormikValues} from 'formik';
import * as Yup from 'yup';

import {ButtonIcon, FormikInput, FormikMultipleFileInput, YupFileArray} from '@reasoncorp/kyber-js';

const MultipleFileInputExample = () => {
  const initialValues = {
    multipleFiles: [],
    multipleFiles2: [],
    multipleFileDescriptions: []
  };

  const validationSchema = Yup.object().shape({
    multipleFiles: new YupFileArray()
      .maxFileSize(1000000, 'must be less than 10mb')
      .acceptedFileTypes(['CSV'], 'Must be a .csv file.')
      .schema()
      .min(3, 'must upload at least 3 files')
      .required('required'),
    multipleFiles2: new YupFileArray()
      .maxFileSize(1000000, 'must be less than 10mb')
      .acceptedFileTypes(['CSV'], 'Must be a .csv file.')
      .schema()
      .required('required')
      .min(3, 'must upload at least 3 files'),
    multipleFileDescriptions: Yup.array(Yup.object({
      description: Yup.string().nullable().required('Required')
    }))
  });
  
  const handleChangeComplete = (formikProps: FormikProps<any>, files: File[]) => {
    const descriptions = [...formikProps.values.multipleFileDescriptions];
    files.forEach((_, index) => {
      if (descriptions[index] === undefined) {
        descriptions[index] = {description: null};
      }
    });
    formikProps.setFieldValue('multipleFileDescriptions', descriptions);
  };

  const renderFiles = ({
                         files,
                         removeFile,
                         field
                       }: {files: File[], removeFile: (index: number, field: FieldInputProps<any>) => void, field: FieldInputProps<any>}) => {
    return <Table bordered responsive striped>
      <thead>
        <tr>
          <th>File Name</th>
          <th>Description</th>
          <th className="w-10 text-center">Remove</th>
        </tr>
      </thead>
      <tbody>
        {files.length === 0 && <tr>
          <td colSpan={3}>No files selected.</td>
        </tr>}
        {files.map((file: File, index: number) => {
          return <tr key={\`file-\${index}\`}>
            <td>
              {file.name}
            </td>
            <td>
              <FormikInput name={\`multipleFileDescriptions[\${index}].description\`}/>
            </td>
            <td className="align-middle text-center">
              <ButtonIcon className="text-danger"
                          title="Remove"
                          icon="trash"
                          onClick={() => removeFile(index, field)}
                          ariaLabel="Remove"/>
            </td>
          </tr>;
        })}
      </tbody>
    </Table>;
  };

  const handleFileRemove = (formikProps: FormikProps<any>) => (index: number) => {
    const multipleFileDescriptionsCopy = [...formikProps.values.multipleFileDescriptions];
    multipleFileDescriptionsCopy.splice(index, 1);
    formikProps.setFieldValue('multipleFileDescriptions', multipleFileDescriptionsCopy);
  };

  return (
    <Formik initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values: FormikValues, actions) => {
              // Normally this would be an API call to a server.
              setTimeout(() => {
                alert('Files: ' + JSON.stringify(values.multipleFiles.map((file: File) => file.name)));
                actions.setSubmitting(false);
              }, 250);
            }}>
      {formikProps => {
        return (
          <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col>
                        <FormikMultipleFileInput name="multipleFiles"
                                                 labelText="Multiple File Upload"
                                                 bsSize="lg"/>
                      </Col>
                    </Row>
                    <p className="mt-3">
                      <code>FormikMultipleFileInput</code> can also customize rendering the list of selected files if passed <code>renderFiles</code>.
                      <code>onRemoveFile</code> is available for custom logic when a file is removed.
                    </p>
                    <Row className="mt-2">
                      <Col xs="12">
                        <Card>
                          <CardBody>
                            <FormikMultipleFileInput name="multipleFiles2"
                                                     labelText="Multiple File Upload (with custom renderFiles)"
                                                     bsSize="lg"
                                                     onChangeComplete={(files: File[]) => handleChangeComplete(formikProps, files)}
                                                     onFileRemove={handleFileRemove(formikProps)}
                                                     renderFiles={renderFiles}/>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="d-flex justify-content-end">
                <Button type="submit" onClick={formikProps.submitForm}>Submit</Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default MultipleFileInputExample`;

const fileInputProps = bootStrapTypeCode.bootstrapFormControlSizeCode + `\n\ntype RenderFilesProps = {
  files: File[]
  removeFile: (index: number, field: FieldInputProps<any>) => void
  field: FieldInputProps<any>
}

type Props = {
  name: string
  buttonText?: string
  buttonColor?: string
  buttonIcon?: IconProp
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, helpers: FieldHelperProps<any>) => void
  id?: string
  labelText?: string
  ariaLabel?: string
  bsSize?: BootstrapFormControlSize
  formGroupClass?: string
  disabled?: boolean
  renderFiles?: (props: RenderFilesProps) => void
  onFileRemove?: (index: number, field: FieldInputProps<any>) => void
  onChangeComplete?: (file: File[]) => void
}`;

const fileInputDefaultProps = `{
  buttonText: 'Upload Files',
  buttonColor: 'primary',
  buttonIcon: 'upload'
}`;

const FormikMultipleFileInputExample = () => {
  const initialValues = {
    multipleFiles: [],
    multipleFiles2: [],
    multipleFileDescriptions: []
  };

  const validationSchema = Yup.object().shape({
    multipleFiles: new YupFileArray()
      .maxFileSize(1000000, 'must be less than 10mb')
      .acceptedFileTypes(['CSV'], 'Must be a .csv file.')
      .schema()
      .required('required')
      .min(3, 'must upload at least 3 files'),
    multipleFiles2: new YupFileArray()
      .maxFileSize(1000000, 'must be less than 10mb')
      .acceptedFileTypes(['CSV'], 'Must be a .csv file.')
      .schema()
      .required('required')
      .min(3, 'must upload at least 3 files'),
    multipleFileDescriptions: Yup.array(Yup.object({
      description: Yup.string().nullable().required('Required')
    }))
  });

  const handleChangeComplete = (formikProps: FormikProps<any>, files: File[]) => {
    const descriptions = [...formikProps.values.multipleFileDescriptions];
    files.forEach((_, index) => {
      if (descriptions[index] === undefined) {
        descriptions[index] = {description: null};
      }
    });
    formikProps.setFieldValue('multipleFileDescriptions', descriptions);
  };

  const renderFiles = ({
                         files,
                         removeFile,
                         field
                       }: {
    files: File[],
    removeFile: (index: number, field: FieldInputProps<any>) => void,
    field: FieldInputProps<any>
  }) => {
    return <Table bordered responsive striped>
      <thead>
        <tr>
          <th>File Name</th>
          <th>Description</th>
          <th className="w-10 text-center">Remove</th>
        </tr>
      </thead>
      <tbody>
        {files.length === 0 && <tr>
          <td colSpan={3}>No files selected.</td>
        </tr>}
        {files.map((file: File, index: number) => {
          return <tr key={`file-${index}`}>
            <td>
              {file.name}
            </td>
            <td>
              <FormikInput name={`multipleFileDescriptions[${index}].description`}/>
            </td>
            <td className="align-middle text-center">
              <ButtonIcon className="text-danger"
                          title="Remove"
                          icon="trash"
                          onClick={() => removeFile(index, field)}
                          ariaLabel="Remove"/>
            </td>
          </tr>;
        })}
      </tbody>
    </Table>;
  };

  const handleFileRemove = (formikProps: FormikProps<any>) => (index: number) => {
    const multipleFileDescriptionsCopy = [...formikProps.values.multipleFileDescriptions];
    multipleFileDescriptionsCopy.splice(index, 1);
    formikProps.setFieldValue('multipleFileDescriptions', multipleFileDescriptionsCopy);
  };

  return (
    <DemoContainer name="FormikMultipleFileInput" section="Forms" componentLinks={componentLinks}>
      <ComponentLayout
        description={
          <p>
            The multiple file input should be used when a form requires several files to be uploaded.
          </p>
        }
        exampleTitle="multiple files"
        componentCodeToRender={fileInputCode}
        componentDefaultPropsToRender={fileInputDefaultProps}
        componentPropsToRender={fileInputProps}>
        <Formik initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnMount={true}
                onSubmit={(values: FormikValues, actions) => {
                  // Normally this would be an API call to a server.
                  setTimeout(() => {
                    alert('Files: ' + JSON.stringify(values.multipleFiles.map((file: File) => file.name)));
                    actions.setSubmitting(false);
                  }, 250);
                }}>
          {formikProps => {
            return (
              <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
                <Row>
                  <Col xs="12">
                    <Card>
                      <CardBody>
                        <FormikMultipleFileInput name="multipleFiles"
                                                 labelText="Multiple File Upload"
                                                 bsSize="lg"/>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <p className="mt-3">
                  <code>FormikMultipleFileInput</code> can also customize rendering the list of selected files if passed <code>renderFiles</code>.
                  <br/>
                  <code>onRemoveFile</code> is available for custom logic when a file is removed.
                </p>
                <Row className="mt-2">
                  <Col xs="12">
                    <Card>
                      <CardBody>
                        <FormikMultipleFileInput name="multipleFiles2"
                                                 labelText="Multiple File Upload (with custom renderFiles)"
                                                 bsSize="lg"
                                                 onChangeComplete={(files: File[]) => handleChangeComplete(formikProps, files)}
                                                 onFileRemove={handleFileRemove(formikProps)}
                                                 renderFiles={renderFiles}/>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col className="d-flex justify-content-end">
                    <Button type="submit"
                            disabled={!formikProps.isValid}
                            onClick={formikProps.submitForm}>Submit</Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </ComponentLayout>
    </DemoContainer>
  );
};

export default FormikMultipleFileInputExample;