import {useEffect, useRef, useState} from 'react';
import Prism from 'prismjs';
import 'prismjs/components/prism-typescript';
import {Button} from 'reactstrap';

type Props = {
  code: string
}

const Code = ({
                code
              }: Props) => {

  const ref = useRef<HTMLElement | null>(null);

  useEffect(() => {
    // Fixes TypeScript highlighting in IE
    if (!Element.prototype.matches) {
      // @ts-ignore
      Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
    }
    if (ref && ref.current !== null) {
      Prism.highlightElement(ref.current as Element);
    }
  }, []);

  const [isDisplayed, setIsDisplayed] = useState(false);
  return (
    <div>
      <Button className="mt-2" color="primary" onClick={() => setIsDisplayed(!isDisplayed)}>
        {isDisplayed ? 'Hide example code' : 'Show example code'}
      </Button>
      <div style={{display: isDisplayed ? '' : 'none'}}>
      <pre tabIndex={0}>
      <code ref={ref} className="language-ts">
        {code}
      </code>
    </pre>
      </div>
    </div>
  );
};

export default Code;