import {ComponentLayout, DemoContainer} from '../../components';
import componentLinks from './componentLinks';

const exampleCode = `//  .schema() must be called after initialization in order to return the actual yup schema instance.
import * as Yup from 'yup';
const schema = new YupPhone("Phone number is invalid.").schema();

schema.isValidSync("(828) 555-5555") // true
schema.isValidSync("(999)") // false

// all validation methods of Yup.string() are supported
const schema = new YupPhone("Phone number is invalid.").schema().required("Phone Number is required");
`;

const YupPhoneExample = () =>
  <DemoContainer name="YupPhone" section="Utilities" componentLinks={componentLinks}>
    <ComponentLayout description="A custom Yup schema for handling validation of U.S. Phone Numbers."
                     componentCodeToRender={exampleCode}/>
  </DemoContainer>;

export default YupPhoneExample;