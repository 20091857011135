import {useState} from 'react';
import {Button} from 'reactstrap';

import {CustomTable, SelectTableCell, SelectTableCellData} from '@reasoncorp/kyber-js';

import {ComponentLayout} from '../../../components';

const exampleCode = `import {Button} from 'reactstrap';

import {CustomTable, SelectTableCell, SelectTableCellData} from '@reasoncorp/kyber-js';


const SelectableTable = () => {
  const [items, setItems] = useState<any[]>([
    {selected: false, id: 1, name: 'ILOVEYOU', quarantine: false, remove: false},
    {selected: false, id: 2, name: 'Code Red', quarantine: false, remove: false},
    {selected: false, id: 3, name: 'Melissa', quarantine: false, remove: false},
    {selected: false, id: 4, name: 'Sasser', quarantine: false, remove: false}
  ]);

  const handleSelectAll = (data: SelectTableCellData) => {
    setItems(items.map(item => data.itemIds.includes(item.id) ? {...item, selected: !data.prevSelection} : item));
  };

  const handleCellSelect = (data: SelectTableCellData) => {
    setItems(items.map(item => item.id === data.itemId ? {...item, selected: !data.prevSelection} : item));
  };

  const handleQuarantineSelect = (data: SelectTableCellData) => {
    setItems(items.map(item => item.id === data.itemId ? {...item, quarantine: !data.prevSelection} : item));
  };

  const handleRemoveSelect = (data: SelectTableCellData) => {
    setItems(items.map(item => item.id === data.itemId ? {...item, remove: !data.prevSelection} : item));
  };

  const handleSubmit = () => {
    alert(JSON.stringify(items));
  };
  
  const tableProps = {
    headers: [
      {selectKey: 'selected', dataIdKey: 'id', htmlIdPrefix: 'selectable-table-advanced', onChange: handleSelectAll},
      {title: 'ID', sortKey: 'id', className: 'align-middle text-center'},
      {title: 'Virus', sortKey: 'virus', className: 'align-middle'},
      {title: 'Quarantine', sortKey: 'quarantine', className: 'align-middle text-center'},
      {title: 'Remove', sortKey: 'remove', className: 'align-middle text-center'}
    ],
    initialSort: {sortKey: 'description', direction: 'asc' as const},
    items: items,
    renderRow: (item: any) => {
      return (
        <tr key={item.id}>
          <SelectTableCell itemId={item.id}
                           selected={item.selected}
                           htmlIdPrefix={'selectable-table-advanced'}
                           ariaLabel={'Select item id' + item.id}
                           onChange={handleCellSelect}/>
          <td className="align-middle text-center">{item.id}</td>
          <td className="align-middle">{item.name}</td>
          <SelectTableCell itemId={item.id}
                           selected={item.quarantine}
                           htmlIdPrefix={'quarantine'}
                           ariaLabel={'Quarantine item id' + item.id}
                           onChange={handleQuarantineSelect}/>
          <SelectTableCell itemId={item.id}
                           selected={item.remove}
                           htmlIdPrefix={'remove'}
                           ariaLabel={'Remove item id' + item.id}
                           onChange={handleRemoveSelect}/>
        </tr>
      );
    },
    paginatorConfig: {
      perPage: 2,
      recordName: 'viruses'
    }
  };

  return (
    <>
      <CustomTable {...tableProps}/>
      <Button onClick={handleSubmit}>Submit</Button>
    </>
  );
};

export default SelectableTable;`;

const SelectableTableAdvanced = () => {
  const [items, setItems] = useState<any[]>([
    {selected: false, id: 1, name: 'ILOVEYOU', quarantine: false, remove: false},
    {selected: false, id: 2, name: 'Code Red', quarantine: false, remove: false},
    {selected: false, id: 3, name: 'Melissa', quarantine: false, remove: false},
    {selected: false, id: 4, name: 'Sasser', quarantine: false, remove: false}
  ]);

  const handleSelectAll = (data: SelectTableCellData) => {
    setItems(items.map(item => data.itemIds.includes(item.id) ? {...item, selected: !data.prevSelection} : item));
  };

  const handleCellSelect = (data: SelectTableCellData) => {
    setItems(items.map(item => item.id === data.itemId ? {...item, selected: !data.prevSelection} : item));
  };

  const handleQuarantineSelect = (data: SelectTableCellData) => {
    setItems(items.map(item => item.id === data.itemId ? {...item, quarantine: !data.prevSelection} : item));
  };

  const handleRemoveSelect = (data: SelectTableCellData) => {
    setItems(items.map(item => item.id === data.itemId ? {...item, remove: !data.prevSelection} : item));
  };

  const handleSubmit = () => {
    alert(JSON.stringify(items));
  };

  const tableProps = {
    headers: [
      {selectKey: 'selected', dataIdKey: 'id', htmlIdPrefix: 'selectable-table-advanced', onChange: handleSelectAll},
      {title: 'ID', sortKey: 'id', className: 'align-middle text-center'},
      {title: 'Virus', sortKey: 'virus', className: 'align-middle'},
      {title: 'Quarantine', sortKey: 'quarantine', className: 'align-middle text-center'},
      {title: 'Remove', sortKey: 'remove', className: 'align-middle text-center'}
    ],
    initialSort: {sortKey: 'description', direction: 'asc' as const},
    items: items,
    renderRow: (item: any) => {
      return (
        <tr key={item.id}>
          <SelectTableCell itemId={item.id}
                           selected={item.selected}
                           htmlIdPrefix={'selectable-table-advanced'}
                           ariaLabel={`Select item id #${item.id}`}
                           onChange={handleCellSelect}/>
          <td className="align-middle text-center">{item.id}</td>
          <td className="align-middle">{item.name}</td>
          <SelectTableCell itemId={item.id}
                           selected={item.quarantine}
                           htmlIdPrefix={'quarantine'}
                           ariaLabel={`Quarantine item id #${item.id}`}
                           onChange={handleQuarantineSelect}/>
          <SelectTableCell itemId={item.id}
                           selected={item.remove}
                           htmlIdPrefix={'remove'}
                           ariaLabel={`Remove item id #${item.id}`}
                           onChange={handleRemoveSelect}/>
        </tr>
      );
    },
    paginatorConfig: {
      perPage: 2,
      recordName: 'viruses'
    }
  };

  return (
    <div id="selectable-table">
      <ComponentLayout exampleTitle="A CustomTable with multiple select columns and pagination."
                       componentCodeToRender={exampleCode}>
        <CustomTable {...tableProps}/>
        <Button onClick={handleSubmit}>Submit</Button>
      </ComponentLayout>
    </div>
  );
};

export default SelectableTableAdvanced;