import {useNavigate} from 'react-router-dom';

import {Code, ComponentLayout, DemoContainer} from '../../components';
import componentLinks from './componentLinks';
import {
  BasicTableExample,
  NoResultsExample,
  SelectableTable,
  SelectableTableAdvanced,
  SortableTable
} from './customTable';

const itemTypeCode = `type Item = {
  id: number
  firstName: string
  lastName: string
  certificationLevel?: string
  certificationNumber?: string
  active?: boolean
}`;

const typeScriptProps = `type BaseHeader = {
  className?: string
  colSpan?: number
  hide?: boolean
}

type StandardHeader = BaseHeader & {
  title?: string
}

type SortableHeader = BaseHeader & {
  title?: string
  sortKey: string
}

type SelectAllHeader = BaseHeader & {
  dataIdKey: string
  htmlIdPrefix?: string // Use to avoid HTML id conflicts across tables, specify unique prefix for table and column
  selectKey: string
  onChange: (data: SelectTableCellData) => void
}

type Header = StandardHeader | SortableHeader | SelectAllHeader;

type SortOrder = {
  sortKey: string
  direction: 'asc' | 'desc'
}

type ResultsLimiterConfig = {
  message: string
  recordName?: string
  pluralRecordName?: string
  limitOptions?: LimitOption[]
  initialResultLimit?: number
}

type PaginatorConfig = {
  perPage: number
  recordName?: string
  currentPage?: number
  setCurrentPage?: (nextPage: number) => void
  allowShowAll? boolean
  showingAll?: boolean
  setShowingAll?: boolean
}

type Props = {
  className?: string
  headers: Header[] | Header[][]
  headerRowClassName?: string
  chainSort?: boolean
  initialSort?: SortOrder | SortOrder[]
  items: object[]
  renderRow: (item: any) => ReactNode
  noResultsMessage?: string
  renderFooter?: () => ReactNode
  resultsLimiterConfig?: ResultsLimiterConfig
  resultLimit?: number | null
  paginatorConfig?: PaginatorConfig
  bordered?: boolean // From Reactstrap Table
  borderless?: boolean // From Reactstrap Table
  dark?: boolean // From Reactstrap Table
  hover?: boolean // From Reactstrap Table
  responsive?: boolean // From Reactstrap Table
  striped?: boolean // From Reactstrap Table
  size?: 'sm' // From Reactstrap Table
}`;

const defaultProps = `{
  bordered: true, // From Reactstrap Table
  borderless: false, // From Reactstrap Table
  dark: false, // From Reactstrap Table
  hover: true, // From Reactstrap Table
  responsive: true, // From Reactstrap Table
  striped: true, // From Reactstrap Table
  size: undefined // From Reactstrap Table
}`;

const CustomTableExample = () => {
  const navigate = useNavigate();

  return <DemoContainer name="CustomTable" section="Tables" componentLinks={componentLinks}>
    <ComponentLayout description={
      <>
        <p>
          CustomTable is a component to allow a developer to reuse common patterns for tables,
          including <a href="#sortable-table">sorting</a>, <a href="#selectable-table">selecting</a>
          , <span className="text-primary cursor-pointer" onClick={() => navigate('/tables/results-limiter' + window.location.search)}>result limiting</span>
          , and <span className="text-primary cursor-pointer" onClick={() => navigate('/tables/paginator' + window.location.search)}>pagination</span>. CustomTable requires a minimum
          of the header, items, and renderRow props passed to it. In the examples below
          the <code>Item</code> type refers to:
        </p>
        <Code code={itemTypeCode}/>
      </>
    }
                     componentPropsToRender={typeScriptProps}
                     componentDefaultPropsToRender={defaultProps}/>
    <hr/>
    <BasicTableExample/>
    <hr/>
    <NoResultsExample/>
    <hr/>
    <SortableTable/>
    <hr/>
    <SelectableTable/>
    <hr/>
    <SelectableTableAdvanced/>
  </DemoContainer>;
};

export default CustomTableExample;