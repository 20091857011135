import {Col, Row} from 'reactstrap';

import {ComponentLayout, DemoContainer} from '../../components';
import componentLinks from './componentLinks';

const exampleCode = `// For Reason theme, import the scss file
// @import '~@reasoncorp/kyber-js/dist/scss/theme-reason';
// for MiSuite theme, import the scss file
// @import '~@reasoncorp/kyber-js/dist/scss/theme-misuite';
<div className="text-primary">Primary Text</div>
<div className="text-secondary">Secondary Text</div>
<div className="text-danger">Danger Text</div>
<div className="text-warning">Warning Text</div>
<div className="text-success">Success Text</div>
`;

const BrandColors = () => {
  return (
    <DemoContainer name="Brand Colors" section="Brand" componentLinks={componentLinks}>
      <ComponentLayout
        description={
          <>
            <p>
              Color is a main characteristic of any visual scene and as a key component of the impact of
              a design on user; it should not be just an aesthetic choice. Brand values and industry
              norms are utilized for creating color palettes for applications. Text and interactive
              elements should have a color contrast ratio of at least 4.5:1 to ensure users who cannot
              see the full color spectrum are able to read the text.
            </p>
            <p>
              The brand colors do not exclusively define the UI elements; however, primary and secondary
              colors should primarily be utilized for links, backgrounds and buttons. The brand colors are global variables
              that should never be overwritten.
            </p>
          </>
        }
        componentCodeToRender={exampleCode}>
        <Row className="mt-4">
          <Col xs={12}>
            <span className="figure-caption text-left">Michigan Department of Treasury Color Codes</span>
          </Col>
          <Col xs={4}>
            <div className="p-3 mr-2 mb-3 mt-2" style={{
              backgroundColor: '#28807C',
              color: '#ECECEC',
              borderRadius: '4px'
            }}>
              MiSuite Primary (#28807C)
            </div>
          </Col>
          <Col xs={4}>
            <div className="p-3 mr-2 mb-3 mt-2" style={{
              backgroundColor: '#51646D',
              color: '#ECECEC',
              borderRadius: '4px'
            }}>
              MiSuite Secondary (#51646D)
            </div>
          </Col>
          <Col xs={4}>
            <div className="p-3 mr-2 mb-3 mt-2" style={{
              backgroundColor: '#B80000',
              color: '#ECECEC',
              borderRadius: '4px'
            }}>
              MiSuite Danger (#B80000)
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <div className="p-3 mr-2 mb-3 mt-2" style={{
              backgroundColor: '#C75300',
              color: '#ECECEC',
              borderRadius: '4px'
            }}>
              MiSuite Warning (#C75300)
            </div>
          </Col>
          <Col xs={4}>
            <div className="p-3 mr-2 mb-3 mt-2" style={{
              backgroundColor: '#158655',
              color: '#ECECEC',
              borderRadius: '4px'
            }}>
              MiSuite Success (#158655)
            </div>
          </Col>
        </Row>
      </ComponentLayout>
    </DemoContainer>
  );
};

export default BrandColors;
