import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';

import {AlertsProvider} from '@reasoncorp/kyber-js';

import App from './App';

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <AlertsProvider>
      <App/>
    </AlertsProvider>
  </BrowserRouter>
);