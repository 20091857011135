import {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {ButtonIcon, CustomTable, SelectTableCell, SelectTableCellData} from '@reasoncorp/kyber-js';

import {ComponentLayout} from '../../../components';
import SelectableItem from '../SelectableItem';

const exampleCode = `import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {ButtonIcon, CustomTable, SelectTableCell, SelectTableCellData} from '@reasoncorp/kyber-js';

import SelectableItem from '../SelectableItem';

const SelectableTable = () => {
  const [items, setItems] = useState<SelectableItem[]>([
    {selected: false, id: 1, firstName: 'Philip', lastName: 'Calouche', active: true},
    {selected: false, id: 2, firstName: 'Becca', lastName: 'Kinard', active: false},
    {selected: false, id: 3, firstName: 'Justin', lastName: 'Baker', active: true},
    {selected: false, id: 4, firstName: 'Jesse', lastName: 'West', active: false}
  ]);

  const handleSelectAll = (data: SelectTableCellData) => {
    setItems(items.map(item => data.itemIds.includes(item.id) ? {...item, selected: !data.prevSelection} : item));
  };

  const handleCellSelect = (data: SelectTableCellData) => {
    setItems(items.map(item => item.id === data.itemId ? {...item, selected: !data.prevSelection} : item));
  };
  
  const tableProps = {
    headers: [
      {selectKey: 'selected', dataIdKey: 'id', htmlIdPrefix:'selectable-table', onChange: handleSelectAll},
      {title: 'ID', sortKey: 'id', className: 'align-middle text-center'},
      {title: 'First Name', sortKey: 'firstName', className: 'align-middle'},
      {title: 'Last Name', sortKey: 'lastName', className: 'align-middle'},
      {title: 'Active', sortKey: 'active', className: 'align-middle text-center'},
      {title: 'Delete', className: 'align-middle text-center'}
    ],
    chainSort: true,
    initialSort: {sortKey: 'active', direction: 'asc' as const},
    items: items,
    renderRow: (item: SelectableItem) => {
      return (
        <tr key={item.id}>
          <SelectTableCell itemId={item.id}
                           selected={item.selected}
                           htmlIdPrefix={'selectable-table'}
                           ariaLabel={'Select item id #' + item.id}
                           onChange={handleCellSelect}/>
          <td className="align-middle text-center">{item.id}</td>
          <td className="align-middle">{item.firstName}</td>
          <td className="align-middle">{item.lastName}</td>
          <td className="align-middle text-center">
            {item.active ?
              <FontAwesomeIcon icon="check"
                               className="text-success"
                               title="active"
                               aria-label="Active"/> :
              <FontAwesomeIcon icon="times"
                               className="text-danger"
                               title="inactive"
                               aria-label="Inactive"/>
            }
          </td>
          <td className="align-middle text-center">
            <ButtonIcon icon="trash"
                        title="Delete role"
                        className="text-danger"
                        ariaLabel="Delete role"
                        onClick={() => alert('You clicked on ' + item.firstName + ' ' + item.lastName + '.')}/>
          </td>
        </tr>
      );
    }
  };

  return <CustomTable {...tableProps}/>;
};

export default SelectableTable;`;

const SelectableTable = () => {
  const [items, setItems] = useState<SelectableItem[]>([
    {selected: false, id: 1, firstName: 'Philip', lastName: 'Calouche', active: true},
    {selected: false, id: 2, firstName: 'Becca', lastName: 'Kinard', active: false},
    {selected: false, id: 3, firstName: 'Justin', lastName: 'Baker', active: true},
    {selected: false, id: 4, firstName: 'Jesse', lastName: 'West', active: false}
  ]);

  const handleSelectAll = (data: SelectTableCellData) => {
    setItems(items.map(item => data.itemIds.includes(item.id) ? {...item, selected: !data.prevSelection} : item));
  };

  const handleCellSelect = (data: SelectTableCellData) => {
    setItems(items.map(item => item.id === data.itemId ? {...item, selected: !data.prevSelection} : item));
  };

  const tableProps = {
    headers: [
      {selectKey: 'selected', dataIdKey: 'id', htmlIdPrefix: 'selectable-table', onChange: handleSelectAll},
      {title: 'ID', sortKey: 'id', className: 'align-middle text-center'},
      {title: 'First Name', sortKey: 'firstName', className: 'align-middle'},
      {title: 'Last Name', sortKey: 'lastName', className: 'align-middle'},
      {title: 'Active', sortKey: 'active', className: 'align-middle text-center'},
      {title: 'Delete', className: 'align-middle text-center'}
    ],
    chainSort: true,
    initialSort: {sortKey: 'active', direction: 'asc' as const},
    items: items,
    renderRow: (item: SelectableItem) => {
      return (
        <tr key={item.id}>
          <SelectTableCell itemId={item.id}
                           selected={item.selected}
                           htmlIdPrefix={'selectable-table'}
                           ariaLabel={`Select item id #${item.id}`}
                           onChange={handleCellSelect}/>
          <td className="align-middle text-center">{item.id}</td>
          <td className="align-middle">{item.firstName}</td>
          <td className="align-middle">{item.lastName}</td>
          <td className="align-middle text-center">
            {item.active ?
              <FontAwesomeIcon icon="check"
                               className="text-success"
                               title="active"
                               aria-label="Active"/> :
              <FontAwesomeIcon icon="times"
                               className="text-danger"
                               title="inactive"
                               aria-label="Inactive"/>
            }
          </td>
          <td className="align-middle text-center">
            <ButtonIcon icon="trash"
                        title="Delete role"
                        className="text-danger"
                        ariaLabel="Delete role"
                        onClick={() => alert('You clicked on ' + item.firstName + ' ' + item.lastName + '.')}/>
          </td>
        </tr>
      );
    }
  };

  return (
    <div id="selectable-table">
      <ComponentLayout exampleTitle="A CustomTable with a selectable column and chain sorting. Sort by active, then by
      first name or last name to see it in action."
                       componentCodeToRender={exampleCode}>
        <CustomTable {...tableProps}/>
      </ComponentLayout>
    </div>
  );
};

export default SelectableTable;