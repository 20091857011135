import {Button, Card, CardBody, Col, Form, Row} from 'reactstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';

import {FormikSelect} from '@reasoncorp/kyber-js';

import {ComponentLayout, DemoContainer} from '../../components';
import {bootStrapTypeCode} from '../../sharedExampleCode';
import componentLinks from './componentLinks';

const selectCode = `import {Button, Card, CardBody, Col, Form, Row} from 'reactstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';

import {FormikSelect} from '@reasoncorp/kyber-js';

const Example = () => {
  const initialValues = {
    petChoice: '',
    favoriteColor: '',
    favoriteSport: ''
  };

  const validationSchema = Yup.object().shape({
    petChoice: Yup.string().oneOf(['Cats', 'Dogs']),
    favoriteColor: Yup.string().oneOf(['Black', 'Red']),
    favoriteSport: Yup.string().oneOf(['Football', 'Soccer', 'Golf', 'Hockey'])
  });

  return (
    <Formik initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              // Normally this would be an API call to a server.
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
                actions.setSubmitting(false);
              }, 250);
            }}>
      {formikProps => {
        return (
          <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
            <Row>
              <Col xs="4">
                <Card>
                  <CardBody>
                    <FormikSelect name="petChoice"
                                  labelText="Pet Choice (large)"
                                  bsSize="lg"
                                  onChange={(e) => {
                                    console.log('This how to handle an additional onChange event', e.target.value);
                                  }}>
                      <option value="">-- Select --</option>
                      <option value="Cats">Cats</option>
                      <option value="Dogs">Dogs</option>
                    </FormikSelect>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="4">
                <Card>
                  <CardBody>
                    <FormikSelect id="favoriteColor"
                                  name="favoriteColor"
                                  labelText="Favorite Color (default size)"
                                  options={['Black', 'Red']} />
                  </CardBody>
                </Card>
              </Col>
              <Col xs="4">
                <Card>
                  <CardBody>
                    <FormikSelect id="favoriteSport"
                                  name="favoriteSport"
                                  labelText="Favorite Sport (small)"
                                  bsSize="sm">
                      <option value="">-- Select --</option>
                      <option value="Football">Football</option>
                      <option value="Soccer">Soccer</option>
                      <option value="Golf">Golf</option>
                      <option value="Hockey">Hockey</option>
                    </FormikSelect>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="d-flex justify-content-end">
                <Button type="submit" onClick={formikProps.submitForm}>Submit</Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Example;`;

const selectProps = bootStrapTypeCode.bootstrapFormControlSizeCode + `\n\ntype SelectOption = {
  key?: string | null
  value: string
  displayValue?: string | null
}

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  [key: string]: any
  id?: string
  name: string
  children: ReactNode
  bsSize?: BootstrapFormControlSize
  formGroupClass?: string
  labelText?: string
  ariaLabel?: string
  icon?: {
    name?: string
    text?: string
  };
  disabled?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputExample>) => void
  noFormikOnChange?: boolean
  invalid?: boolean
  value?: any
  options?: string[] | SelectOption[]
  showBlankOption?: boolean
  blankOptionText?: string
}`;

const FormikSelectExample = () => {
  const initialValues = {
    petChoice: '',
    favoriteColor: '',
    favoriteSport: ''
  };

  const validationSchema = Yup.object().shape({
    petChoice: Yup.string().oneOf(['Cats', 'Dogs']),
    favoriteColor: Yup.string().oneOf(['Black', 'Red']),
    favoriteSport: Yup.string().oneOf(['Football', 'Soccer', 'Golf', 'Hockey'])
  });

  return (
    <DemoContainer name="FormikSelect" section="Forms" componentLinks={componentLinks}>
      <ComponentLayout
        description={<p>
          <code>FormikSelect</code> should be used when only one option can be chosen based on a predetermined list of options.
        </p>}
        exampleTitle="various"
        componentCodeToRender={selectCode}
        componentPropsToRender={selectProps}>
        <Formik initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  // Normally this would be an API call to a server.
                  setTimeout(() => {
                    alert(JSON.stringify(values, null, 2));
                    actions.setSubmitting(false);
                  }, 250);
                }}>
          {formikProps => {
            return (
              <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
                <Row>
                  <Col xs="4">
                    <Card>
                      <CardBody>
                        <FormikSelect name="petChoice"
                                      labelText="Pet Choice (large)"
                                      bsSize="lg"
                                      onChange={(e) => {
                                        console.log('This how to handle an additional onChange event', e.target.value);
                                      }}>
                          <option value="">-- Select --</option>
                          <option value="Cats">Cats</option>
                          <option value="Dogs">Dogs</option>
                        </FormikSelect>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xs="4">
                    <Card>
                      <CardBody>
                        <FormikSelect id="favoriteColor"
                                      name="favoriteColor"
                                      labelText="Favorite Color (default size)"
                                      options={['Black', 'Red']}/>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xs="4">
                    <Card>
                      <CardBody>
                        <FormikSelect id="favoriteSport"
                                      name="favoriteSport"
                                      labelText="Favorite Sport (small)"
                                      bsSize="sm">
                          <option value="">-- Select --</option>
                          <option value="Football">Football</option>
                          <option value="Soccer">Soccer</option>
                          <option value="Golf">Golf</option>
                          <option value="Hockey">Hockey</option>
                        </FormikSelect>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col className="d-flex justify-content-end">
                    <Button type="submit" onClick={formikProps.submitForm}>Submit</Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </ComponentLayout>
    </DemoContainer>
  );
};

export default FormikSelectExample;
