import {ProgressIndicator} from '@reasoncorp/kyber-js';

import {ComponentLayout, DemoContainer} from '../../components';
import componentLinks from './componentLinks';

const exampleCode = `import {ProgressIndicator} from '@reasoncorp/kyber-js';

const ProgressIndicatorExample = () => {
  return <ProgressIndicator/>;
};

export default ProgressIndicatorExample;`;

const componentProps = [
  {name: 'className', type: 'string', required: false},
  {name: 'color', type: 'BootstrapColor', required: false, defaultValue: 'primary'}
];

const ProgressIndicatorExample = () => {
  return (
    <DemoContainer name="ProgressIndicator" section="Components" componentLinks={componentLinks}>
      <ComponentLayout description={
        <p>
          Progress indicators inform users about the status of ongoing processes, such as loading
          an app, submitting a form, or saving updates. They communicate an app’s state and indicate
          available actions, such as whether users can navigate away from the current screen.
        </p>
      }
                       exampleTitle="Progress Indicator"
                       componentCodeToRender={exampleCode}
                       componentProps={componentProps}>
        <ProgressIndicator/>
        <br/>
      </ComponentLayout>
    </DemoContainer>
  );
};

export default ProgressIndicatorExample;
