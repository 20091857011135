import {useState} from 'react';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

import {StatefulButton} from '@reasoncorp/kyber-js';

import {ComponentLayout, DemoContainer} from '../../components';
import componentLinks from './componentLinks';

const exampleCode = `import {useState} from 'react';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

import {StatefulButton} from '@reasoncorp/kyber-js';

const StatefulButtonExample = () => {
  const [activeState, setActiveState] = useState<string>('button1');
  
  const states = [
    {name: 'button1', text: 'Button 1'},
    {name: 'button2', text: 'Button 2', icon: 'trash' as IconProp},
    {name: 'button3', text: 'Button 3', icon: 'redo' as IconProp, spinIcon: true}
  ];

  const rotateButtons = () => {
    let currentIndex = states.findIndex(state => state.name === activeState);
    if (currentIndex < states.length - 1) {
      setActiveState(states[currentIndex + 1].name);
    } else {
      setActiveState(states[0].name);
    }
  };

  return <StatefulButton states={states} 
                         activeState={activeState} 
                         onClick={rotateButtons}/>
};

export default StatefulButtonExample;`;

const componentProperties = [
  {name: 'states', type: '{\n  name: string;\n  text: string;\n  icon?: IconProp\n  spinIcon?: boolean\n}[]'},
  {name: 'activeState', type: 'string', required: false},
  {name: 'className', type: 'string', required: false}
];

const StatefulButtonExample = () => {
  const [activeState, setActiveState] = useState<string>('button1');

  const states = [
    {name: 'button1', text: 'Button 1'},
    {name: 'button2', text: 'Button 2', icon: 'trash' as IconProp},
    {name: 'button3', text: 'Button 3', icon: 'redo' as IconProp, spinIcon: true}
  ];

  const rotateButtons = () => {
    let currentIndex = states.findIndex(state => state.name === activeState);
    if (currentIndex < states.length - 1) {
      setActiveState(states[currentIndex + 1].name);
    } else {
      setActiveState(states[0].name);
    }
  };

  return (
    <DemoContainer name="StatefulButton" section="Components" componentLinks={componentLinks}>
      <ComponentLayout description={
        <p>
          Stateful Buttons introduce state to button so they can be used programmatically to change looks and behavior.
          It uses Reactstrap's Button component under the covers, so all props for that are available to it.
        </p>
      }
                       exampleTitle="Click to rotate stateful buttons"
                       componentCodeToRender={exampleCode}
                       componentProps={componentProperties}>
        <StatefulButton states={states}
                        activeState={activeState}
                        onClick={rotateButtons}/>
      </ComponentLayout>
    </DemoContainer>
  );
};

export default StatefulButtonExample;
