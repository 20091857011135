import {ButtonIcon} from '@reasoncorp/kyber-js';

import {ComponentLayout, DemoContainer} from '../../components';
import componentLinks from './componentLinks';

const buttonsCode = `import {ButtonIcon} from '@reasoncorp/kyber-js';

const ButtonIconExample = () => {
  return (
    <div>
      <ButtonIcon className="text-success"
                  ariaLabel="Icon Button"
                  title="button"
                  icon="trash"
                  onClick={() => console.log('SUCCESS')}/>
      <br/>
      <ButtonIcon className="text-success"
                  ariaLabel="Icon Button"
                  title="button"
                  icon="trash"
                  spinIcon
                  onClick={() => console.log('SUCCESS')}/>
    </div>
  );
};

export default ButtonIconExample;`;

const componentProperties = [
  {name: 'ariaLabel', type: 'string'},
  {name: 'title', type: 'string'},
  {name: 'size', type: 'BootStrapButtonSize', required: false, defaultValue: 'md'},
  {name: 'icon', type: 'IconProp'},
  {name: 'spinIcon', type: 'boolean', required: false, defaultValue: 'false'}
];

const ButtonIconExample = () => {
  return (
    <DemoContainer name="ButtonIcon" section="Components" componentLinks={componentLinks}>
      <ComponentLayout description={
        <p>
          <code>ButtonIcon</code> allows a developer to display an icon as a clickable button.
          It uses Reactstrap's Button component under the covers, so all props for that are available to it.
        </p>
      }
                       exampleTitle="Button"
                       componentCodeToRender={buttonsCode}
                       componentProps={componentProperties}>
        <div>
          <ButtonIcon className="text-success"
                      ariaLabel="Icon Button"
                      title="button"
                      icon="trash"
                      onClick={() => console.log('SUCCESS')}/>
          <br/>
          <ButtonIcon className="text-success"
                      ariaLabel="Icon Button"
                      title="button"
                      icon="trash"
                      spinIcon
                      onClick={() => console.log('SUCCESS')}/>
        </div>
      </ComponentLayout>
    </DemoContainer>
  );
};

export default ButtonIconExample;
