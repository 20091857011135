import {AppBar, SsoNotificationButton, UserNotification, UserProfile} from '@reasoncorp/kyber-js';

import {RkLogo} from '../images';

type Props = {
  version: string
}

const DemoAppBar = ({
                      version
                    }: Props) => {
  const userNotification = {
    unread: [
      {
        id: 1,
        text: 'Notification 1',
        timeAgo: '1 minute ago',
        subject: 'Notification 1',
        read: false,
        application: 'MEG',
        createdAt: new Date()
      },
      {
        id: 2,
        subject: 'Notification that is longer',
        text: 'Notification that is longer',
        timeAgo: '5 minutes ago',
        read: false,
        application: 'MEG',
        createdAt: new Date()
      },
      {
        id: 3,
        subject: 'This notification is particularly wordy. I am curious how well it will wrap the text.',
        text: 'This notification is particularly wordy. I am curious how well it will wrap the text.',
        timeAgo: '1 hour ago',
        read: false,
        application: 'MEG',
        createdAt: new Date()
      },
      {
        id: 4,
        text: 'Finish the MEG application.',
        subject: 'Finish the MEG application.',
        timeAgo: '15 hours ago',
        read: false,
        application: 'MEG',
        createdAt: new Date()
      }, {
        id: 5,
        text: 'Finish the MEGA application.',
        subject: 'Finish the MEGA application.',
        timeAgo: '1 day ago',
        read: false,
        application: 'MEG',
        createdAt: new Date()
      }, {
        id: 6,
        text: 'Finish the PA660 application.',
        subject: 'Finish the PA660 application.',
        timeAgo: '1,234 days ago',
        read: false,
        application: 'MEG',
        createdAt: new Date()
      }, {
        id: 7,
        subject: 'Convert SSO to TypeScript.',
        text: 'Convert SSO to TypeScript.',
        timeAgo: 'Less than a minute ago',
        read: false,
        application: 'MEG',
        createdAt: new Date()
      }],
    read: [],
    archived: []
  } as UserNotification;

  const ssoApi: {
    findNotifications: () => Promise<UserNotification>
  } = {
    findNotifications: () => new Promise(() => userNotification)
  };

  const appBarProps = {
    brandLink: '/',
    brandImage: RkLogo,
    brandImageAlt: 'MiSuite',
    appName: `Kyber Js v${version}`,
    organizationName: 'Reason Consulting',
    routes: [
      {name: 'Layout', to: '/layout' + window.location.search},
      {name: 'Brand', to: '/brand' + window.location.search},
      {name: 'Forms', to: '/forms' + window.location.search},
      {name: 'Tables', to: '/tables' + window.location.search},
      {name: 'Components', to: '/components' + window.location.search},
      {name: 'Utilities', to: '/utilities' + window.location.search}
    ],
    // Some dummy findNotifications for testing
    userNotification,
    // Each app may need to control how its AppBar widgets are configured because they may need to reference other app logic.
    // By creating render props for each of these widgets this allows for easier composition of these widgets.
    renderNotificationButton: () =>
      // A non-demo application will have other checks in here such as to render nothing if the user is not logged in
      <SsoNotificationButton ssoApi={ssoApi}
                             onNotificationsClick={() => alert('Would navigate to MiMessaging')}/>,
    renderUserProfile: () =>
      // A non-demo application will have other checks in here such as to render nothing if the user is not logged in
      <UserProfile handleProfileClick={() => alert('In a real app this would go to your profile.')}
                   handleSignOut={() => alert('You cannot actually sign out because you are not signed in.')}/>
  };

  return (
    <AppBar {...appBarProps}/>
  );
};

export default DemoAppBar;