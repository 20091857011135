import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {ButtonIcon, CustomTable} from '@reasoncorp/kyber-js';

import {ComponentLayout} from '../../../components';
import Item from '../Item';

export const exampleCode = `import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {ButtonIcon, CustomTable} from '@reasoncorp/kyber-js';

import Item from '../Item';

const TableExample = () => {
  // Defining tableProps as an object can help with readability.
  const tableProps = {
    headers: [
      {title: 'ID', className: 'align-middle text-center'},
      {title: 'First Name', className: 'align-middle'},
      {title: 'Last Name', className: 'align-middle'},
      {title: 'Active', className: 'align-middle text-center'},
      {title: 'Delete', className: 'align-middle text-center'}
    ],
    // items may come from state or an ajax call. For example's sake this can be hardcoded since the table is readOnly
    items: [
      {id: 1, firstName: 'Philip', lastName: 'Calouche', active: true},
      {id: 2, firstName: 'Becca', lastName: 'Kinard', active: false},
      {id: 3, firstName: 'Justin', lastName: 'Baker', active: true},
      {id: 4, firstName: 'Jesse', lastName: 'West', active: false}
    ],
    renderRow: (item: Item) =>
      <tr key={item.id}>
        <td className="align-middle text-center">{item.id}</td>
        <td className="align-middle">{item.firstName}</td>
        <td className="align-middle">{item.lastName}</td>
        <td className="align-middle text-center">
          {item.active ?
            <FontAwesomeIcon icon="check"
                             className="text-success"
                             title="active"
                             aria-label="Active"/> :
            <FontAwesomeIcon icon="times"
                             className="text-danger"
                             title="inactive"
                             aria-label="Inactive"/>
          }
        </td>
        <td className="align-middle text-center">
          <ButtonIcon icon="trash"
                      title="Delete role"
                      className="text-danger"
                      ariaLabel="Delete role"
                      onClick={() => alert('You clicked on ' + item.firstName + ' ' + item.lastName + '.')}/>
        </td>
      </tr>
  };

  return <CustomTable {...tableProps}/>;         
};

export default TableExample;`;

const TableExample = () => {
  // Defining tableProps as an object can help with readability.
  const tableProps = {
    headers: [
      {title: 'ID', className: 'align-middle text-center'},
      {title: 'First Name', className: 'align-middle'},
      {title: 'Last Name', className: 'align-middle'},
      {title: 'Active', className: 'align-middle text-center'},
      {title: 'Delete', className: 'align-middle text-center'}
    ],
    // items may come from state or an ajax call. For example's sake this can be hardcoded since the table is readOnly
    items: [
      {id: 1, firstName: 'Philip', lastName: 'Calouche', active: true},
      {id: 2, firstName: 'Becca', lastName: 'Kinard', active: false},
      {id: 3, firstName: 'Justin', lastName: 'Baker', active: true},
      {id: 4, firstName: 'Jesse', lastName: 'West', active: false}
    ],
    renderRow: (item: Item) =>
      <tr key={item.id}>
        <td className="align-middle text-center">{item.id}</td>
        <td className="align-middle">{item.firstName}</td>
        <td className="align-middle">{item.lastName}</td>
        <td className="align-middle text-center">
          {item.active ?
            <FontAwesomeIcon icon="check"
                             className="text-success"
                             title="active"
                             aria-label="Active"/> :
            <FontAwesomeIcon icon="times"
                             className="text-danger"
                             title="inactive"
                             aria-label="Inactive"/>
          }
        </td>
        <td className="align-middle text-center">
          <ButtonIcon icon="trash"
                      title="Delete role"
                      className="text-danger"
                      ariaLabel="Delete role"
                      onClick={() => alert('You clicked on ' + item.firstName + ' ' + item.lastName + '.')}/>
        </td>
      </tr>
  };

  return (
    <ComponentLayout exampleTitle="Most Basic CustomTable showing icons and button icons."
                     componentCodeToRender={exampleCode}>
      <CustomTable {...tableProps}/>
    </ComponentLayout>
  );
};

export default TableExample;
