export interface AppEnvironmentConfig {
  [domain: string]: {
    envName: string
    apiHost: string
  };
}

const config: AppEnvironmentConfig = {
  'localhost': {
    envName: 'dev',
    apiHost: `${window.location.protocol}//${window.location.hostname}:3000`
  },
  'kyber-js.reason.consulting': {
    envName: 'prod',
    apiHost: `${window.location.protocol}//${window.location.hostname}`
  }
};

export default config[window.location.hostname] || config['localhost'];
