import {Button, Card, CardBody, CardFooter, CardHeader, Col, Form, Row} from 'reactstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';

import {FormikInput, FormikPasswordInput} from '@reasoncorp/kyber-js';

import {ComponentLayout, DemoContainer} from '../../components';
import componentLinks from './componentLinks';

const formCode = `import {Button, Card, CardBody, Col, Form, Row} from 'reactstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';

import {FormikInput, FormikPasswordInput} from '@reasoncorp/kyber-js';

const Example = () => {
  const initialValues = {
    email: '',
    email2: '',
    password: '',
    password2: ''
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required('Required'),
    password: Yup.string().required('Required')
  });

  const validationSchema2 = Yup.object().shape({
    email2: Yup.string().email().required('Required'),
    password2: Yup.string().required('Required')
  });

  return (
    <>
      <Formik initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                // Normally this would be an API call to a server.
                setTimeout(() => {
                  alert(JSON.stringify(values, null, 2));
                  actions.setSubmitting(false);
                }, 250);
              }}>
        {formikProps => {
          return (
            <Form
              onSubmit={formikProps.handleSubmit}
              autoComplete="off">
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <FormikInput name="email" labelText="Email"/>
                      <FormikPasswordInput name="password" labelText="Password"/>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="d-flex justify-content-end">
                  <Button type="submit" onSubmit={formikProps.submitForm}>
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
      <Formik initialValues={initialValues}
              validationSchema={validationSchema2}
              validateOnMount={true}
              onSubmit={(values, actions) => {
                // Normally this would be an API call to a server.
                setTimeout(() => {
                  alert(JSON.stringify(values, null, 2));
                  actions.setSubmitting(false);
                }, 250);
              }}>
        {formikProps => {
          return (
            <Form
              onSubmit={formikProps.handleSubmit}
              autoComplete="off">
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <FormikInput name="email2" labelText="Email"/>
                      <FormikPasswordInput name="password2" labelText="Password"/>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="d-flex justify-content-end">
                  <Button type="submit" onSubmit={formikProps.submitForm}>
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default Example;`;

const FormExample = () => {
  const initialValues = {
    email: '',
    email2: '',
    password: '',
    password2: ''
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required('Required'),
    password: Yup.string().required('Required')
  });

  return (
    <DemoContainer name="Forms" section="Forms" componentLinks={componentLinks}>
      <ComponentLayout
        description={
          <p>
            React Kyber form components are designed to use <a href="https://formik.org/" target="_blank" rel="noopener noreferrer">Formik</a> and <a href="https://github.com/jquense/yup" target="_blank" rel="noopener noreferrer">Yup</a> libraries for managing form state and validation.
          </p>
        }
        exampleTitle="basic form"
        componentCodeToRender={formCode}>
        <>
          <Formik initialValues={initialValues}
                  validationSchema={validationSchema}
                  validateOnMount={true}
                  onSubmit={(values, actions) => {
                    // Normally this would be an API call to a server.
                    setTimeout(() => {
                      alert(JSON.stringify(values, null, 2));
                      actions.setSubmitting(false);
                    }, 250);
                  }}>
            {formikProps => {
              return (
                <Form onSubmit={formikProps.handleSubmit}
                      autoComplete="off">
                  <Row className="mb-4">
                    <Col xs="12">
                      <Card>
                        <CardHeader>
                          Login
                        </CardHeader>
                        <CardBody>
                          <FormikInput name="email" labelText="Email"/>
                          <FormikPasswordInput name="password" labelText="Password"/>
                        </CardBody>
                        <CardFooter className="d-flex justify-content-end">
                          <Button type="submit" onSubmit={formikProps.submitForm}>
                            Login
                          </Button>
                        </CardFooter>
                      </Card>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </>
      </ComponentLayout>
    </DemoContainer>
  );
};

export default FormExample;
