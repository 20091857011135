import {IconProp} from '@fortawesome/fontawesome-svg-core';

import {BreadcrumbsNav} from '@reasoncorp/kyber-js';

import {ComponentLayout, DemoContainer} from '../../components';
import componentLinks from './componentLinks';

const exampleCode = `import {IconProp} from '@fortawesome/fontawesome-svg-core';

import {BreadcrumbsNav} from '@reasoncorp/kyber-js';

const BreadcrumbsExample = () => {
  const breadcrumbs = [
    {text: 'Home', icon: 'home' as IconProp, active: false, route: '/'},
    {text: 'Components', active: false, route: '/components'},
    {text: 'Not Found', active: true, route: '/not-found'}
  ];
  
  return (
    <BreadcrumbsNav breadcrumbs={breadcrumbs}/>
  );
};

export default BreadcrumbsExample;`;

const componentProps = [
  {
    name: 'breadcrumbs',
    type: 'breadcrumbs: {\n    text: string\n    icon?: IconProp\n    route?: string\n    active?: boolean\n  }[]'
  },
  {
    name: 'inline',
    type: 'boolean',
    required: false,
    defaultValue: "false"
  }
];

const BreadcrumbsExample = () => {
  const breadcrumbs = [
    {text: 'Home', icon: 'home' as IconProp, active: false, route: '/'},
    {text: 'Components', active: false, route: '/components'},
    {text: 'Not Found', active: true, route: '/not-found'}
  ];

  return (
    <DemoContainer name="Breadcrumbs" section="Components" componentLinks={componentLinks}>
      <ComponentLayout description={
        <p>
          Breadcrumbs are an effective visual aid that indicates the location of the user within
          the website’s hierarchy. The breadcrumb trail indicates the current page location and
          the previous pages they viewed to get to the current page location. In terms of usability,
          breadcrumbs reduce the number of actions a user needs to take in order to get to a
          higher-level page, and they improve the findability within the trail.
        </p>}
                       exampleTitle="Breadcrumb Trail"
                       componentCodeToRender={exampleCode}
                       componentProps={componentProps}>
        <BreadcrumbsNav breadcrumbs={breadcrumbs}/>
      </ComponentLayout>
    </DemoContainer>
  );
};

export default BreadcrumbsExample;
