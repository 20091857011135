import {Button, Card, CardBody, Col, Form, Row} from 'reactstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';

import {FormikInput} from '@reasoncorp/kyber-js';

import {ComponentLayout, DemoContainer} from '../../components';
import {inputPropsTypeCode} from '../../sharedExampleCode';
import componentLinks from './componentLinks';

const exampleCode = `import {Card, CardBody, Col, Form, Row} from 'reactstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';

import {FormikInput} from '@reasoncorp/kyber-js';

const FormikInputExample = () => {
  const initialValues = {
    firstName: '',
    middleName: '',
    lastName: 'Doe',
    longQuestion: '',
    textarea1: '',
    textarea2: '',
    textarea3: ''
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    middleName: Yup.string(),
    lastName: Yup.string().required('Required'),
    longQuestion: Yup.string().required('Required'),
     textarea1: Yup.string(),
    textarea2: Yup.string(),
    textarea3: Yup.string()
  });

  return (
    <Formik initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              // Normally this would be an API call to a server.
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
                actions.setSubmitting(false);
              }, 250);
            }}>
      {formikProps => {
        return (
          <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
            <Row>
              <Col xs="4">
                <Card>
                  <CardBody>
                    <FormikInput name="firstName"
                                 labelText="First Name"/>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="4">
                <Card>
                  <CardBody>
                    <FormikInput name="middleName"
                                 labelText="Middle Name"/>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="4">
                <Card>
                  <CardBody>
                    <FormikInput name="lastName"
                                 labelText="Last Name"
                                 disabled/>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <p className="mt-4">Icons may also be used within <code>FormikInput</code> labels.</p>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <FormikInput name="search"
                                 onChange={(e) => {
                                   console.log('This how to handle an additional onChange event', e.target.value);
                                 }}
                                 labelText="Search (Labels can be given an Icon)"
                                 icon={{name: 'search', text: 'Search'}}/>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <p className="mt-2">
              The floating label behavior can be disabled with the disableFloatingLabel property and a
              larger-label class can be applied as well. This is good for cases where the form label is
              really long such as a questionnaire.
            </p>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <FormikInput name="longQuestion"
                                 labelText="MCL 211.7cc requires interest at a rate of 1.25% per month or fraction of a month
                                                         to be charged to the owner of property that has been issued a PRE denial notice. Upon collecting
                                                         the interest, MCL 211.7cc also details the required distribution of the interest depending on
                                                         the governmental unit that issued the denial notice. Was Form 4142 completed and submitted
                                                         to the Michigan Department of Treasury by a County, City or Township when the State’s
                                                         portion of PRE denial interest is remitted?"
                                 formGroupClass="larger-label"
                                 disableFloatingLabel={true}/>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <p className="mt-2">
              <code>FormikInput</code> can also render textarea elements if passed <code>type="textarea"</code>.
            </p>
            <Row>
              <Col xs="4">
                <Card>
                  <CardBody>
                    <FormikInput name="textarea1"
                                 labelText="Text Area"
                                 type="textarea"
                                 rows={6}/>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="4">
                <Card>
                  <CardBody>
                    <FormikInput name="textarea2"
                                 labelText="Text Area (Small)"
                                 type="textarea"
                                 bsSize="sm"
                                 rows={6}/>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="4">
                <Card>
                  <CardBody>
                    <FormikInput name="textarea3"
                                 labelText="Text Area (Large)"
                                 bsSize="lg"
                                 type="textarea"
                                 rows={6}/>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="d-flex justify-content-end">
                <Button type="submit" onClick={formikProps.submitForm}>Submit</Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FormikInputExample;`;

const defaultPropTypes = `{
  type: 'text',
  disableFloatingLabel: false
}`;

const FormikInputExample = () => {
  const initialValues = {
    firstName: '',
    middleName: '',
    lastName: 'Doe',
    longQuestion: '',
    textarea1: '',
    textarea2: '',
    textarea3: ''
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    middleName: Yup.string(),
    lastName: Yup.string().required('Required'),
    longQuestion: Yup.string().required('Required'),
    textarea1: Yup.string(),
    textarea2: Yup.string(),
    textarea3: Yup.string()
  });

  return (
    <DemoContainer name="FormikInput" section="Forms" componentLinks={componentLinks}>
      <ComponentLayout
        description={<p>
          <code>FormikInput</code> should be used when a value cannot be predetermined with out the user.
        </p>}
        exampleTitle="required, not required, disabled, and search Inputs"
        componentCodeToRender={exampleCode}
        componentDefaultPropsToRender={defaultPropTypes}
        componentPropsToRender={inputPropsTypeCode.inputPropsType}>
        <Formik initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  // Normally this would be an API call to a server.
                  setTimeout(() => {
                    alert(JSON.stringify(values, null, 2));
                    actions.setSubmitting(false);
                  }, 250);
                }}>
          {formikProps => {
            return (
              <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
                <Row>
                  <Col xs="4">
                    <Card>
                      <CardBody>
                        <FormikInput name="firstName"
                                     labelText="First Name"/>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xs="4">
                    <Card>
                      <CardBody>
                        <FormikInput name="middleName"
                                     labelText="Middle Name"/>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xs="4">
                    <Card>
                      <CardBody>
                        <FormikInput name="lastName"
                                     labelText="Last Name"
                                     disabled/>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <p className="mt-4">Icons may also be used within <code>FormikInput</code> labels.</p>
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        <FormikInput name="search"
                                     onChange={(e) => {
                                       console.log('This how to handle an additional onChange event', e.target.value);
                                     }}
                                     labelText="Search (Labels can be given an Icon)"
                                     icon={{name: 'search', text: 'Search'}}/>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <p className="mt-2">
                  The floating label behavior can be disabled with the disableFloatingLabel property and a
                  larger-label class can be applied as well. This is good for cases where the form label is
                  really long such as a questionnaire.
                </p>
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        <FormikInput name="longQuestion"
                                     labelText="MCL 211.7cc requires interest at a rate of 1.25% per month or fraction of a month
                                                             to be charged to the owner of property that has been issued a PRE denial notice. Upon collecting
                                                             the interest, MCL 211.7cc also details the required distribution of the interest depending on
                                                             the governmental unit that issued the denial notice. Was Form 4142 completed and submitted
                                                             to the Michigan Department of Treasury by a County, City or Township when the State’s
                                                             portion of PRE denial interest is remitted?"
                                     formGroupClass="larger-label"
                                     disableFloatingLabel={true}/>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <p className="mt-2">
                  <code>FormikInput</code> can also render textarea elements if passed <code>type="textarea"</code>.
                </p>
                <Row>
                  <Col xs="4">
                    <Card>
                      <CardBody>
                        <FormikInput name="textarea1"
                                     labelText="Text Area"
                                     type="textarea"
                                     rows={6}/>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xs="4">
                    <Card>
                      <CardBody>
                        <FormikInput name="textarea2"
                                     labelText="Text Area (Small)"
                                     type="textarea"
                                     bsSize="sm"
                                     rows={6}/>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xs="4">
                    <Card>
                      <CardBody>
                        <FormikInput name="textarea3"
                                     labelText="Text Area (Large)"
                                     bsSize="lg"
                                     type="textarea"
                                     rows={6}/>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col className="d-flex justify-content-end">
                    <Button type="submit" onClick={formikProps.submitForm}>Submit</Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </ComponentLayout>
    </DemoContainer>
  );
};

export default FormikInputExample;
