import {useState} from 'react';
import {Button} from 'reactstrap';

import {ProgressModal} from '@reasoncorp/kyber-js';

import {ComponentLayout, DemoContainer} from '../../components';
import componentLinks from './componentLinks';


const exampleCode = `import {useState} from 'react';
import {Button} from 'reactstrap';

import {ProgressModal} from '@reasoncorp/kyber-js';

const ProgressModalExample = () => {
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  const handleGenerateReport1 = () => {
    setIsOpen1(true);
    setTimeout(() => setIsOpen1(false), 5000);
  };

  const handleGenerateReport2 = () => {
    setIsOpen2(true);
    setTimeout(() => setIsOpen2(false), 5000);
  };

  const reactNode = (
    <ol>
      <li>Relax</li>
      <li>Get some coffee</li>
      <li>Comeback to check on me</li>
    </ol>
  );
  
  return (
    <div>
      <Button color="primary"
              className="mr-3"
              onClick={handleGenerateReport1}>
        Generate Report 1
      </Button>
      <Button color="primary"
              onClick={handleGenerateReport2}>
        Generate Report 2
      </Button>
      <ProgressModal isOpen={isOpen1}
                     title="Generating Report 1"
                     content="Report is being generated. Please do not refresh the page, as this could take a few moments."/>
      <ProgressModal isOpen={isOpen2}
                     title="Generating Report 2"
                     content={reactNode}/>
      </div>
  );
}

export default ProgressIndicatorExample;`;

const componentProps = [
  {name: 'isOpen', type: 'boolean'},
  {name: 'title', type: 'ReactNode'},
  {name: 'content', type: 'ReactNode'}
];

const ProgressModalExample = () => {
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  const handleGenerateReport1 = () => {
    setIsOpen1(true);
    setTimeout(() => setIsOpen1(false), 5000);
  };

  const handleGenerateReport2 = () => {
    setIsOpen2(true);
    setTimeout(() => setIsOpen2(false), 5000);
  };

  const olStyle = {paddingInlineStart: '1rem'};

  const reactNode = <>
    <ol style={olStyle}>
      <li>Relax</li>
      <li>Get some coffee</li>
      <li>Come back to check on me</li>
    </ol>
  </>;

  return (
    <DemoContainer name="ProgressModal" section="Components" componentLinks={componentLinks}>
      <ComponentLayout description={
        <p>
          Progress modals inform users about the status of ongoing processes, such as waiting
          for a report to complete. They are meant to halt a users progress until the action is
          complete or fails, so they are not able to be dismissed. If a plain string is used for the
          content it will be wrapped in a paragraph tag, otherwise the React node will be used for
          the content.
        </p>
      }
                       exampleTitle="Progress Modal"
                       componentCodeToRender={exampleCode}
                       componentProps={componentProps}>
        <div>
          <Button color="primary"
                  className="mr-3"
                  onClick={handleGenerateReport1}>
            Generate Report 1
          </Button>
          <Button color="primary"
                  onClick={handleGenerateReport2}>
            Generate Report 2
          </Button>
          <ProgressModal isOpen={isOpen1}
                         title="Generating Report 1"
                         content="Report is being generated. Please do not refresh the page, as this could take a few moments."/>
          <ProgressModal isOpen={isOpen2}
                         title="Generating Report 2"
                         content={reactNode}/>
        </div>
      </ComponentLayout>
    </DemoContainer>
  );
};

export default ProgressModalExample;
