import {Button, Card, CardBody, Col, Form, Row} from 'reactstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';

import {FormikPercentageInput} from '@reasoncorp/kyber-js';

import {ComponentLayout, DemoContainer} from '../../components';
import {inputPropsTypeCode} from '../../sharedExampleCode';
import componentLinks from './componentLinks';

const formCode = `import {Button, Card, CardBody, Col, Form, Row} from 'reactstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';

import {FormikPercentageInput} from '@reasoncorp/kyber-js';

const PercentageInputExample = () => {
  const initialValues = {
    percentage: 99.33
  };

  const validationSchema = Yup.object().shape({
    percentage: Yup.number()
      .typeError('Must be a percentage.')
      .max(100, 'Must be less than 100.00%')
  });

  return (
    <Formik initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              // Normally this would be an API call to a server.
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
                actions.setSubmitting(false);
              }, 250);
            }}>
      {formikProps => {
        return (
          <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <FormikPercentageInput
                      name="percentage"
                      labelText="Custom Percentage Input"
                      maxLength={7}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="d-flex justify-content-end">
                <Button type="submit" onClick={formikProps.submitForm}>Submit</Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PercentageInputExample;`;

const formikPercentageInputPropTypes = inputPropsTypeCode.inputPropsType + `\n\ntype Props = InputProps & {
  name: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, formikActions: FieldHelperProps<any>) => void
}`;

const FormikPercentageInputExample = () => {
  const initialValues = {
    percentage: 99.33
  };

  const validationSchema = Yup.object().shape({
    percentage: Yup.number()
      .typeError('Must be a percentage.')
      .max(100, 'Must be less than 100.00%')
  });

  return (
    <DemoContainer name="FormikPercentageInput" section="Forms" componentLinks={componentLinks}>
      <ComponentLayout
        description={
          <>
            <p>
              <code>FormikPercentageInput</code> handles auto-formatting percentage input values.
            </p>
            <p>
              <code>FormikPercentageInput</code> wraps <code>FormikInput</code>, so all prop types are supported
              &nbsp;except for <code>type</code>, <code>onBlur</code>, <code>onKeyUp</code>,
              &nbsp;and <code>noFormikOnChange</code>.
            </p>
          </>
        }
        exampleTitle="percentage input"
        componentCodeToRender={formCode}
        componentPropsToRender={formikPercentageInputPropTypes}>
        <Formik initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  // Normally this would be an API call to a server.
                  setTimeout(() => {
                    alert(JSON.stringify(values, null, 2));
                    actions.setSubmitting(false);
                  }, 250);
                }}>
          {formikProps => {
            return (
              <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
                <Row>
                  <Col xs="12">
                    <Card>
                      <CardBody>
                        <FormikPercentageInput
                          name="percentage"
                          labelText="Custom Percentage Input"
                          maxLength={7}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col className="d-flex justify-content-end">
                    <Button type="submit" onClick={formikProps.submitForm}>Submit</Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </ComponentLayout>
    </DemoContainer>
  );
};

export default FormikPercentageInputExample;
