import {Button, Card, CardBody, Col, Form, Row} from 'reactstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';

import {FormikNumberInput} from '@reasoncorp/kyber-js';

import {ComponentLayout, DemoContainer} from '../../components';
import {inputPropsTypeCode} from '../../sharedExampleCode';
import componentLinks from './componentLinks';

const formCode = `import {Button, Card, CardBody, Col, Form, Row} from 'reactstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';

import {FormikNumberInput} from '@reasoncorp/kyber-js';

const NumberInputExample = () => {
  const initialValues = {
    number: 1000,
    number2: 1000
  };

  const validationSchema = Yup.object().shape({
    number: Yup.number()
      .typeError('Must be a number.')
      .max(100000, 'Must be less than 100,000'),
    number2: Yup.number()
      .typeError('Must be a number.')
      .max(100000, 'Must be less than 100,000'),
    number3: Yup.number()
      .typeError('Must be a number.')
      .max(100000, 'Must be less than 100,000')
  });

  return (
    <Formik initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              // Normally this would be an API call to a server.
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
                actions.setSubmitting(false);
              }, 250);
            }}>
      {formikProps => {
        return (
          <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col>
                        <FormikNumberInput
                          name="number"
                          labelText="Custom Number Input"
                          maxLength={7}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormikNumberInput
                          name="number2"
                          labelText="Custom Number Input (with signs)"
                          maxLength={8}
                          includeSign={true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormikNumberInput
                          name="number3"
                          labelText="Custom Number Input (without commas)"
                          maxLength={6}
                          includeCommas={false}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="d-flex justify-content-end">
                <Button type="submit" onClick={formikProps.submitForm}>Submit</Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default NumberInputExample;`;

const formikNumberInputPropTypes = inputPropsTypeCode.inputPropsType + `\n\ntype Props = InputProps & {
  name: string
  onChange?: (e: React.ChangeEvent<HtmlInputExample>, formikActions: FieldHelperProps<any>) => void
  includeSign?: boolean
}`;

const FormikNumberInputExample = () => {
  const initialValues = {
    number: 1000,
    number2: 1000,
    number3: 1000
  };

  const validationSchema = Yup.object().shape({
    number: Yup.number()
      .typeError('Must be a number.')
      .max(100000, 'Must be less than 100,000'),
    number2: Yup.number()
      .typeError('Must be a number.')
      .max(100000, 'Must be less than 100,000'),
    number3: Yup.number()
      .typeError('Must be a number.')
      .max(100000, 'Must be less than 100,000')
  });

  return (
    <DemoContainer name="FormikNumberInput" section="Forms" componentLinks={componentLinks}>
      <ComponentLayout
        description={<>
          <p>
            <code>FormikNumberInput</code> handles auto-formatting number input values.
          </p>
          <p>
            <code>FormikNumberInput</code> wraps <code>FormikInput</code>, so all prop types are supported except for
            &nbsp;<code>type</code>, and <code>noFormikOnChange</code>.
          </p>
          <p>
            <code>includeSign</code> may be passed as a prop to allow prefixing positive numbers with a <code>+</code>.
          </p>
        </>
        }
        exampleTitle="number input"
        componentCodeToRender={formCode}
        componentPropsToRender={formikNumberInputPropTypes}>
        <Formik initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  // Normally this would be an API call to a server.
                  setTimeout(() => {
                    alert(JSON.stringify(values, null, 2));
                    actions.setSubmitting(false);
                  }, 250);
                }}>
          {formikProps => {
            return (
              <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
                <Row>
                  <Col xs="12">
                    <Card>
                      <CardBody>
                        <Row>
                          <Col>
                            <FormikNumberInput
                              name="number"
                              labelText="Custom Number Input"
                              maxLength={7}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormikNumberInput
                              name="number2"
                              labelText="Custom Number Input (with signs)"
                              maxLength={8}
                              includeSign={true}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormikNumberInput
                              name="number3"
                              labelText="Custom Number Input (without commas)"
                              maxLength={6}
                              includeCommas={false}
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col className="d-flex justify-content-end">
                    <Button type="submit" onClick={formikProps.submitForm}>Submit</Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </ComponentLayout>
    </DemoContainer>
  );
};

export default FormikNumberInputExample;
