import {useState} from 'react';
import {Button} from 'reactstrap';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

import {ConfirmationModal} from '@reasoncorp/kyber-js';

import {ComponentLayout, DemoContainer} from '../../components';
import componentLinks from './componentLinks';

const exampleCode = `import {useState} from 'react';
import {Button, Col, Row} from 'reactstrap';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

import {ConfirmationModal} from '@reasoncorp/kyber-js';

const ConfirmationModalExample = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [lastResult, setLastResult] = useState('N/A');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleConfirmation = (result: string) => {
    setIsSubmitting(true);
    // Simulates a server request
    setTimeout(() => {
      setLastResult(result);
      setIsOpen(false);
    }, 1000);
  };

  const handleCancel = (result: string) => {
    setLastResult(result);
    setIsOpen(false);
  };

  return (
    <>
      <Button color="primary"
              onClick={() => {
                setIsOpen(true);
                setIsSubmitting(false);
              }}>
        Launch Confirmation Modal (No Close Icon)
      </Button>
      <p>Last Result: <strong>{lastResult}</strong></p>
      <ConfirmationModal isOpen={isOpen}
                         size="lg"
                         title="Confirmation Modal (No Close Icon)"
                         showCloseIcon={false}
                         confirmButtonText={isSubmitting ? 'Saving' : 'Yes'}
                         confirmButtonDisabled={isSubmitting}
                         confirmButtonIcon={isSubmitting ? 'spinner' as IconProp : undefined}
                         confirmButtonIconSpin={isSubmitting}
                         confirmCallback={() => handleConfirmation('Yes')}
                         cancelButtonText="No"
                         cancelButtonDisabled={isSubmitting}
                         cancelCallback={() => handleCancel('No')}>
        <p>Are you using React Kyber?</p>
      </ConfirmationModal>
    </>
  );
};

export default ConfirmationModalExample;`;

const componentProps = [
  {name: 'isOpen', type: 'boolean'},
  {name: 'title', type: 'string', required: false},
  {name: 'showCloseIcon', type: 'boolean', required: false, defaultValue: 'true'},
  {name: 'size', type: 'BootstrapModalSize', required: false},
  {name: 'confirmButtonText', type: 'string', required: false, defaultValue: 'Ok'},
  {name: 'confirmButtonColor', type: 'BootstrapColor', required: false, defaultValue: 'success'},
  {name: 'confirmButtonDisabled', type: 'boolean', required: false, defaultValue: 'false'},
  {name: 'confirmButtonIcon', type: 'IconProp', required: false},
  {name: 'confirmButtonIconSpin', type: 'boolean', required: false, defaultValue: 'false'},
  {name: 'confirmCallback', type: `(e: React.MouseEvent<HTMLButtonElement>) => void`},
  {name: 'cancelButtonText', type: 'string', required: false, defaultValue: 'Cancel'},
  {name: 'cancelButtonColor', type: 'BootstrapColor', required: false, defaultValue: 'secondary'},
  {name: 'cancelButtonDisabled', type: 'boolean', required: false, defaultValue: 'false'},
  {name: 'cancelCallback', type: `(e: React.MouseEvent<HTMLButtonElement>) => void`},
  {name: 'children', type: 'ReactNode'}
];

const ConfirmationModalExample = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleConfirmation = (confirmed: boolean) => {
    setIsSubmitting(true);
    // Simulates a server request
    setTimeout(() => {
      setConfirmed(confirmed);
      setIsOpen(false);
    }, 1000);
  };

  const handleCancel = (confirmed: boolean) => {
    setConfirmed(confirmed);
    setIsOpen(false);
  };

  return (
    <DemoContainer name="ConfirmationModal" section="Components" componentLinks={componentLinks}>
      <ComponentLayout description={
        <p>
          A confirmation modal, or dialog, asks a user to approve a requested operation before
          performing a destructive action. The user should have the ability to cancel the action
          or complete the action from within the dialog focus.
        </p>
      }
                       exampleTitle="Confirmation Modal"
                       componentCodeToRender={exampleCode}
                       componentProps={componentProps}>
        <>
          <Button color="primary"
                  onClick={() => {
                    setIsOpen(true);
                    setIsSubmitting(false);
                  }}>
            Launch Confirmation Modal
          </Button>
          <p>Confirmed: <strong>{confirmed ? 'Yes' : 'No'}</strong></p>
          <ConfirmationModal isOpen={isOpen}
                             size="lg"
                             title="Confirmation Modal (No Close Icon)"
                             showCloseIcon={false}
                             confirmButtonText={isSubmitting ? 'Saving' : 'Yes'}
                             confirmButtonDisabled={isSubmitting}
                             confirmButtonIcon={isSubmitting ? 'spinner' as IconProp : undefined}
                             confirmButtonIconSpin={isSubmitting}
                             confirmCallback={() => handleConfirmation(true)}
                             cancelButtonText="No"
                             cancelButtonDisabled={isSubmitting}
                             cancelCallback={() => handleCancel(false)}>
            <p>Are you using React Kyber?</p>
          </ConfirmationModal>
        </>
      </ComponentLayout>
    </DemoContainer>
  );
};

export default ConfirmationModalExample;
