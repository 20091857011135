const links = [
  {name: 'Forms', path: '/forms'},
  {name: 'FormikAutoComplete', path: '/forms/autocomplete'},
  {name: 'FormikCheckboxGroup', path: '/forms/checkbox'},
  {name: 'FormikDatePicker', path: '/forms/datepicker'},
  {name: 'FormikDecimalInput', path: '/forms/decimal'},
  {name: 'FormikFileInput', path: '/forms/file'},
  {name: 'FormikInput', path: '/forms/input'},
  {name: 'FormikMultipleFileInput', path: '/forms/multiple-file'},
  {name: 'FormikNumberInput', path: '/forms/number'},
  {name: 'FormikPasswordInput', path: '/forms/password'},
  {name: 'FormikPercentageInput', path: '/forms/percentage'},
  {name: 'FormikPhoneInput', path: '/forms/phone'},
  {name: 'FormikRadioGroup', path: '/forms/radio'},
  {name: 'FormikSelect', path: '/forms/select'}
];

export default links;