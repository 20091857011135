import {NotFoundV2} from '@reasoncorp/kyber-js';

import {ComponentLayout, DemoContainer} from '../../components';
import componentLinks from './componentLinks';

const exampleCode = `import {Route, Switch} from 'react-router-dom';

import {NotFound} from '@reasoncorp/kyber-js';

const PageNotFoundExample = () => {
  return (
    <Switch>
      // other routes for your app would exist here
      <Route exact path="/" component={StarterPage}/>
      <Route path="/app-drawer" component={AppDrawerPage}/>
      // define the route that would display the component
      <Route exact component={NotFound}/>
    </Switch>
  );
};

export default PageNotFoundExample;`;

const PageNotFoundExample = () => {
  return (
    <DemoContainer name="NotFound" section="Layout" componentLinks={componentLinks}>
      <ComponentLayout
        description={
          <p>
            A 404 page is an error message that displays for pages that don’t actually exist on
            a website. If the user stumbles on a 404 page using an old bookmark or strange characters
            on a web address, the 404 page should should funnel them back to the site.
          </p>
        }
        componentCodeToRender={exampleCode}>
        <NotFoundV2/>
      </ComponentLayout>
    </DemoContainer>
  );
};

export default PageNotFoundExample;
