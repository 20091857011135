import {Footer} from '@reasoncorp/kyber-js';

import {ComponentLayout, DemoContainer} from '../../components';
import componentLinks from './componentLinks';

const exampleCode = `import {Footer} from '@reasoncorp/kyber-js';

const FooterExample = () => {
  return (
    <Footer brand="Reason Consulting"
            brandLink="https://reason.consulting"
            footerLinks={[
              {name: 'GitHub', to: 'https://github.com/reasoncorp/kyber-js'}
            ]}/>
  );
};

export default FooterExample;`;

const componentProps = [
  {name: 'brand', type: 'string'},
  {name: 'brandLink', type: 'string'},
  {name: 'footerLinks', type: '{name: string, to: string}[]'}
];

const FooterExample = () => {
  return (
    <DemoContainer section="Layout" name="Footer" componentLinks={componentLinks}>
      <ComponentLayout description={
        <p>
          The full width, sticky <code>Footer</code> component provides a default place for copyright information and additional links.
        </p>}
                       componentCodeToRender={exampleCode}
                       componentProps={componentProps}>
        <Footer brand="Reason Consulting"
                brandLink="https://reason.consulting"
                footerLinks={[
                  {name: 'GitHub', to: 'https://github.com/reasoncorp/kyber-js'}
                ]}/>
      </ComponentLayout>
    </DemoContainer>
  );
};

export default FooterExample;
