import {CustomTable} from '@reasoncorp/kyber-js';

import {ComponentLayout} from '../../../components';
import Item from '../Item';

const exampleCode = `import {CustomTable} from '@reasoncorp/kyber-js';

import Item from '../Item';

const NoResultsExample = () => {
  const tableProps = {
    headers: [
      {title: 'ID', className: 'align-middle text-center'},
      {title: 'First Name', className: 'align-middle'},
      {title: 'Last Name', className: 'align-middle'},
      {title: 'Certification Level', className: 'align-middle text-center'},
      {title: 'Certification Number', className: 'align-middle text-center'}
    ],
    items: [],
    renderRow: (item: Item) => {
      return (
        <tr key={item.id}>
          <td className="align-middle text-center">{item.id}</td>
          <td className="align-middle">{item.firstName}</td>
          <td className="align-middle">{item.lastName}</td>
          <td className="align-middle text-center">{item.certificationLevel}</td>
          <td className="align-middle text-center">{item.certificationNumber}</td>
        </tr>
      );
    },
    noResultsMessage: 'No items found (message can be whatever you want)'
  };

  return <CustomTable {...tableProps}/>;
};

export default NoResultsExample`;

const NoResultsExample = () => {
  const tableProps = {
    headers: [
      {title: 'ID', className: 'align-middle text-center'},
      {title: 'First Name', className: 'align-middle'},
      {title: 'Last Name', className: 'align-middle'},
      {title: 'Certification Level', className: 'align-middle text-center'},
      {title: 'Certification Number', className: 'align-middle text-center'}
    ],
    items: [],
    renderRow: (item: Item) => {
      return (
        <tr key={item.id}>
          <td className="align-middle text-center">{item.id}</td>
          <td className="align-middle">{item.firstName}</td>
          <td className="align-middle">{item.lastName}</td>
          <td className="align-middle text-center">{item.certificationLevel}</td>
          <td className="align-middle text-center">{item.certificationNumber}</td>
        </tr>
      );
    },
    noResultsMessage: 'No items found (message can be whatever you want)'
  };

  return (
    <ComponentLayout exampleTitle="No results message"
                     componentCodeToRender={exampleCode}>
      <CustomTable {...tableProps}/>
    </ComponentLayout>
  );
};

export default NoResultsExample;