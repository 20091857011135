import {Col, Row} from 'reactstrap';
import {useNavigate} from 'react-router-dom';

import {ComponentLayout, DemoContainer} from '../../components';
import componentLinks from './componentLinks';

const exampleCode = `const ColorsExample = () => {
  return <>
    <span className="figure-caption text-left">Example: <span className="text-success">Success and Encouragement</span></span>
    <div className="bg-success p-4 mb-4"/>
    <span className="figure-caption text-left">Example: <span className="text-warning">Caution or Instruction</span></span>
    <div className="bg-warning p-4 mb-4"/>
    <span className="figure-caption text-left">Example: <span className="text-danger">Errors and Importance</span></span>
    <div className="bg-danger p-4"/>
  </>
};

export default ColorsExample;
`;

const ColorsExample = () => {
  const navigate = useNavigate();

  // noinspection HtmlUnknownAnchorTarget
  return (
    <DemoContainer name="Color Usage" section="Brand" componentLinks={componentLinks}>
      <ComponentLayout
        description={
          <>
            <p>
              An indicator is a way of making a page element stand out to inform the user that there is
              something that warrants the users attention. Contextual colors are used as indicators,
              as users are familiar with their associations, such as on a traffic light. Green is used
              as a positive, successful action indicator signifying the action is correct, saved or
              complete. Orange is used for that of caution, waiting or immediate instructions to carry
              out. Red is used as a warning, an error or something crucial for a user to see and
              usually signifies an irreversible action.
            </p>
            <p>
              Contextual colors can be used for backgrounds or text; however, they will not apply color
              to an element that requires a fill color, such as an image or an icon.
              (See: <span className="text-primary cursor-pointer" onClick={() => navigate('/brand/icons' + window.location.search)}>Icons</span>). These are global variables that should never
              be overwritten.
            </p>
          </>
        }
        componentCodeToRender={exampleCode}>
        <Row>
          <Col>
            <span className="figure-caption text-left">Example: <span className="text-success">Success and Encouragement</span></span>
            <div className="bg-success p-4 mb-4"/>
            <span className="figure-caption text-left">Example: <span className="text-warning">Caution or Instruction</span></span>
            <div className="bg-warning p-4 mb-4"/>
            <span className="figure-caption text-left">Example: <span className="text-danger">Errors and Importance</span></span>
            <div className="bg-danger p-4"/>
          </Col>
        </Row>
      </ComponentLayout>
    </DemoContainer>
  );
};

export default ColorsExample;
