import {Formik} from 'formik';

import {FormikSelect, UsState} from '@reasoncorp/kyber-js';

import {Code, DemoContainer} from '../../components';
import componentLinks from './componentLinks';

const statesMapCode = `import {Formik} from 'formik';

import {FormikSelect, UsState} from '@reasoncorp/kyber-js';

const UsStateExample = () => {
  return (
    <Formik initialValues={{state: ''}} onSubmit={(values) => alert(JSON.stringify(values))}>
      {(formikProps) => 
        <>
          <FormikSelect name="state">
            {Object.entries(UsState).map(([abbr, name]) => {
                return <option key={abbr} value={abbr}>{abbr} - {name}</option>;
              }
            )}
          </FormikSelect>
          <div>Select state is {formikProps.values.state}</div>
        </>
      }}
    </Formik>
  );
};

export default UsStateExample;`;

const UsStateExample = () => {
  return <DemoContainer name="UsState" section="Utilities" componentLinks={componentLinks}>
    <p>
      A predefined enum representing U.S. states. The name of the enum is the state abbreviation and the value is
      is the full state name. This is primarily used for populating a select options for an address.
    </p>
    <Formik initialValues={{state: ''}} onSubmit={(values) => alert(JSON.stringify(values))}>
      {(formikProps) =>
        <>
          <FormikSelect name="state">
            {Object.entries(UsState).map(([abbr, name]) => {
                return <option key={abbr} value={abbr}>{abbr} - {name}</option>;
              }
            )}
          </FormikSelect>
          <div>Select state is {formikProps.values.state}</div>
        </>
      }
    </Formik>
    <Code code={statesMapCode}/>
  </DemoContainer>;
};

export default UsStateExample;