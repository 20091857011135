const links = [
  {name: 'AlertModal', path: '/components/alert-modal', rootPath: '/components'},
  {name: 'Alerts', path: '/components/alerts'},
  {name: 'Breadcrumbs', path: '/components/breadcrumbs'},
  {name: 'ButtonIcon', path: '/components/button-icon'},
  {name: 'ConfirmationModal', path: '/components/confirmation-modal'},
  {name: 'FormikModal', path: '/components/formik-modal'},
  {name: 'ProgressIndicator', path: '/components/progress-indicator'},
  {name: 'ProgressModal', path: '/components/progress-modal'},
  {name: 'StatefulButton', path: '/components/stateful-button'},
  {name: 'TabNav', path: '/components/tab-nav'}
];

export default links;