import {useEffect, useMemo, useState} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import {Alerts, Footer, NotFoundV2, SkipNav} from '@reasoncorp/kyber-js';

import './font-awesome-icons';
import {About, brand, components, forms, layout, tables, utilities} from './containers';
import {DemoAppBar, ThemeSelector} from './components';
import {getVersion} from './api';

const App = () => {
  const [version, setVersion] = useState('');

  const footerProps = useMemo(() => ({
    brand: 'Reason Consulting',
    brandLink: 'https://reason.consulting',
    footerLinks: [{
      name: 'GitHub',
      to: 'https://github.com/reasoncorp/kyber-js'
    }]
  }), []);

  useEffect(() => {
    const loadVersion = async () => {
      if (!version) {
        const version = await getVersion();
        setVersion(version.kyberJsVersion);
      }
    };

    void loadVersion();
  });

  return (
    <>
      <SkipNav/>
      <DemoAppBar version={version}/>
      <main role="main" id="content">
        <div>
          <ThemeSelector/>
        </div>
        <Routes>
          <Route path="/" element={<Navigate replace to="/about"/>}/>
          <Route path="/about" element={<About/>}/>

          {/* layout routes */}
          <Route path="/layout" element={<layout.Structure/>}/>
          <Route path="/layout/structure" element={<layout.Structure/>}/>
          <Route path="/layout/appbar" element={<layout.AppBarExample/>}/>
          <Route path="/layout/footer" element={<layout.FooterExample/>}/>
          <Route path="/layout/notfound" element={<layout.PageNotFoundExample/>}/>

          {/* brand routes */}
          <Route path="/brand" element={<brand.BrandColorsExample/>}/>
          <Route path="/brand/brand-colors" element={<brand.BrandColorsExample/>}/>
          <Route path="/brand/color-usage" element={<brand.ColorsExample/>}/>
          <Route path="/brand/icons" element={<brand.IconsExample/>}/>

          {/* form routes */}
          <Route path="/forms" element={<forms.FormExample/>}/>
          <Route path="/forms/autocomplete" element={<forms.FormikAutocompleteExample/>}/>
          <Route path="/forms/checkbox" element={<forms.FormikCheckboxGroupExample/>}/>
          <Route path="/forms/datepicker" element={<forms.FormikDatePickerExample/>}/>
          <Route path="/forms/decimal" element={<forms.FormikDecimalInputExample/>}/>
          <Route path="/forms/file" element={<forms.FormikFileInputExample/>}/>
          <Route path="/forms/input" element={<forms.FormikInputExample/>}/>
          <Route path="/forms/multiple-file" element={<forms.FormikMultipleFileInputExample/>}/>
          <Route path="/forms/number" element={<forms.FormikNumberInputExample/>}/>
          <Route path="/forms/password" element={<forms.FormikPasswordInputExample/>}/>
          <Route path="/forms/percentage" element={<forms.FormikPercentageInputExample/>}/>
          <Route path="/forms/phone" element={<forms.FormikPhoneInputExample/>}/>
          <Route path="/forms/radio" element={<forms.FormikRadioGroupExample/>}/>
          <Route path="/forms/select" element={<forms.FormikSelectExample/>}/>

          {/* table routes */}
          <Route path="/tables" element={<tables.CustomTableExample/>}/>
          <Route path="/tables/custom-table" element={<tables.CustomTableExample/>}/>
          <Route path="/tables/paginator" element={<tables.PaginatorExample/>}/>
          <Route path="/tables/results-limiter" element={<tables.ResultsLimiterExample/>}/>

          {/*  component  routes */}
          <Route path="/components" element={<components.AlertModalExample/>}/>
          <Route path="/components/alert-modal" element={<components.AlertModalExample/>}/>
          <Route path="/components/alerts" element={<components.AlertsExample/>}/>
          <Route path="/components/breadcrumbs" element={<components.BreadcrumbsExample/>}/>
          <Route path="/components/button-icon" element={<components.ButtonIconExample/>}/>
          <Route path="/components/confirmation-modal" element={<components.ConfirmationModalExample/>}/>
          <Route path="/components/formik-modal" element={<components.FormikModalExample/>}/>
          <Route path="/components/progress-indicator" element={<components.ProgressIndicatorExample/>}/>
          <Route path="/components/progress-modal" element={<components.ProgressModalExample/>}/>
          <Route path="/components/stateful-button" element={<components.StatefulButtonExample/>}/>
          <Route path="/components/tab-nav" element={<components.TabNavExample/>}/>

          {/* utility routes */}
          <Route path="/utilities" element={<utilities.RaygunExample/>}/>
          <Route path="/utilities/raygun" element={<utilities.RaygunExample/>}/>
          <Route path="/utilities/rest-api-helper" element={<utilities.RestApiHelperExample/>}/>
          <Route path="/utilities/us-state" element={<utilities.UsStateExample/>}/>
          <Route path="/utilities/yup-file-array" element={<utilities.YupFileArrayExample/>}/>
          <Route path="/utilities/yup-file" element={<utilities.YupFileExample/>}/>
          <Route path="/utilities/yup-phone" element={<utilities.YupPhoneExample/>}/>
          <Route path="*" element={<NotFoundV2/>}/>
        </Routes>
      </main>

      <Footer {...footerProps}/>
      <Alerts/>
    </>
  );
};

export default App;
